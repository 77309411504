import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  Divider,
  List,
  InputNumber,
} from "antd";
import {
  PlusOutlined,
  MinusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import {
  fetchData,
  createCategory,
  updateCategory,
  createLowCategory,
  updateLowCategory,
  createSubCategory,
  updateSubCategory,
} from "../actions/category";

import { Table, Tag, Space, Popconfirm, message, Image, Switch } from "antd";

import SingleUpload from "../component/SingleUpload";
import { Cascader } from "antd";
import { PageHeader, Descriptions } from "antd";
import { useHistory } from "react-router-dom";

import {
  createData as createCMS,
  fetchData as fetchCMS,
  putData as putCMS,
} from "../actions/cms";
import { fetchData as fetchLookup } from "../actions/category";

import { getStateCountry, filterCountryState } from "../utils/mappers";

import { SectionTags } from "../component/SectionTags";

const { Option } = Select;
const { TextArea } = Input;

function PageCategory({
  data,
  getData,
  addCMS,
  updateCMS,
  REFRESH,
  lookup,
  addUnit,
  unitData,
  bypassData,
  updateProduct,
  getLookup,
  categoryData,
  updateCat,
  updateSubCat,
  updateLowCat,
  categoryCascade,
  cascadeCategory,
  addCategory,
  addSubCategory,
  addLowCategory,
}) {
  const [showFilter, toggleFilter] = React.useState(false);

  const [showDrawer, toggleDrawer] = React.useState(false);

  const [editData, updateEditData] = React.useState({});

  const [tagList, setTagList] = useState("");
  const [materialList, setMaterialList] = useState("");

  const history = useHistory();

  const updateDrawer = (showDrawer = false) => {
    toggleDrawer(showDrawer);
  };

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    getLookup({ query: "" });
    updateDrawer(false);
  }, [REFRESH]);

  const onFormSubmit = (values) => {
    console.log("form data", values);

    let data = {
      ...editData,
      ...values,
      tags: tagList,
      material: materialList,
      url: values.url,
    };

    console.log("toggle filter dialog called", data);

    if (data && data.name) {
      if (data.category && data.category.length == 1) {
        if (data.id) {
          updateSubCat(data.id, data);
        } else {
          addSubCategory({ ...data, cat_id: data.category[0] });
        }
      } else if (data.category && data.category.length == 2) {
        if (data.id) {
          updateLowCat(data.id, data);
        } else {
          addLowCategory({ ...data, subcat_id: data.category[1] });
        }
      } else {
        delete data.category;
        if (data.id) {
          updateCat(data.id, data);
        } else {
          addCategory(data);
        }
      }
    }

    updateDrawer();
  };

  const [updateItem, setUpdateItem] = React.useState(false);

  const onItemEdit = (data, record, index) => {
    console.log(data, record, index);

    setUpdateItem(true);
    setMaterialList(data.material);
    setTagList(data.tags);

    if (data.lowcat_id) {
      updateEditData({
        name: data.name,
        tags: data.tags,
        material: data.material,
        subcat_id: data.subcat_id,
        id: data.lowcat_id,
        sort_order: data.sort_order,
        isUpdate: updateItem,
        category: [data.cat_id, data.subcat_id],
        slug: data.slug,
        url: data.url,
      });
    } else if (data.subcat_id) {
      updateEditData({
        name: data.name,
        tags: data.tags,
        cat_id: data.cat_id,
        material: data.material,
        slug: data.slug,
        sort_order: data.sort_order,

        id: data.subcat_id,
        isUpdate: updateItem,
        category: [data.cat_id],
        price: data.price,
        discount: data.discount,
        is_private: data.is_private,
        url: data.url,
      });
    } else {
      updateEditData({
        name: data.name,
        tags: data.tags,
        material: data.material,
        slug: data.slug,
        sort_order: data.sort_order,

        id: data.cat_id,
        isUpdate: updateItem,
        url: data.url,
      });
    }

    toggleDrawer(true);
  };

  useEffect(() => {
    form.setFieldsValue(editData);
  }, [editData]);

  const onToggleItem = (data, record, index) => {
    if (data.lowcat_id) {
      updateLowCat(data.lowcat_id, { ...data, status: !data.status });
    } else if (data.subcat_id) {
      updateSubCat(data.subcat_id, { ...data, status: !data.status });
    } else {
      updateCat(data.cat_id, { ...data, status: !data.status });
    }
  };

  const onPrivateItem = (data, record, index) => {
    if (data.lowcat_id) {
      updateLowCat(data.lowcat_id, { ...data, is_private: !data.is_private });
    } else if (data.subcat_id) {
      updateSubCat(data.subcat_id, { ...data, is_private: !data.is_private });
    } else {
      updateCat(data.cat_id, { ...data, is_private: !data.is_private });
    }
  };

  const onDeleteItem = (data, record, index) => {
    if (data.lowcat_id) {
      updateLowCat(data.lowcat_id, { ...data, is_deleted: !data.is_deleted });
    } else if (data.subcat_id) {
      updateSubCat(data.subcat_id, { ...data, is_deleted: !data.is_deleted });
    } else {
      updateCat(data.cat_id, { ...data, is_deleted: !data.is_deleted });
    }
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 400,
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (item, render, index) => (
        <>
          {item.split(",").map((tag) => (
            <Tag color="blue" key={tag}>
              {tag}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: "Material",
      dataIndex: "material",
      key: "material",
      render: (item, render, index) => (
        <>
          {item.split(",").map((tag) => (
            <Tag color="blue" key={tag}>
              {tag}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: "Sort Order",
      dataIndex: "sort_order",
      key: "name",
      width: 400,
    },
    {
      title: "Status",
      key: "status",
      render: (item, record, index) => (
        <Space size="middle">
          <Switch
            checked={item.status}
            onChange={() => onToggleItem(item, record, index)}
          />
        </Space>
      ),
    },
    {
      title: "Private",
      key: "is_private",
      render: (item, record, index) => {
        if (item.is_private != null) {
          return (
            <Space size="middle">
              <Switch
                checked={item.is_private}
                onChange={() => onPrivateItem(item, record, index)}
              />
            </Space>
          );
        }
      },
    },
    {
      title: "Action",
      key: "action",
      render: (item, record, index) => (
        <Space size="middle">
          <a onClick={() => onItemEdit(item, record, index)}>Edit</a>
          <Popconfirm
            title="Are you sure to delete this?"
            onConfirm={() => onDeleteItem(item, record, index)}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <a href="#" style={{ color: "red" }}>
              Delete
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  const [newItem, addNewItem] = useState("");
  const onNameChange = (event) => {
    addNewItem(event.target.value);
  };

  const addItem = (data) => {
    console.log(data);

    if (data != "") addUnit({ name: data });

    addNewItem("");
  };

  return (
    <div className="site-page-header-ghost-wrapper">
      <PageHeader
        title="Category"
        className="site-page-header"
        extra={[
          <Button key="3" onClick={() => {}}>
            Clear Filter
          </Button>,

          <Button key="2" onClick={() => toggleFilter(true)}>
            Filters
          </Button>,

          <Button
            key="1"
            type="primary"
            onClick={() => {
              form.resetFields();
              updateEditData({
                name: "",
                category: [],
                id: false,
              });
              setMaterialList("");
              setTagList("");
              updateDrawer(true);
            }}
          >
            Add
          </Button>,
        ]}
      ></PageHeader>
      <Table
        columns={columns}
        dataSource={categoryCascade}
        size="small"
        pagination={false}
      />
      <Drawer
        title="Create Category"
        width={600}
        onClose={() => updateDrawer(false)}
        visible={showDrawer}
        maskClosable={false}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => updateDrawer(false)}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                form.submit();
              }}
              type="primary"
            >
              Submit
            </Button>
          </div>
        }
      >
        <Form form={form} layout="vertical" onFinish={onFormSubmit}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="category" label="Category">
                <Cascader options={cascadeCategory} changeOnSelect />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    message: "Please input category name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              {editData.category && editData.category.length == 1 && (
                <Form.Item name="price" label="Market Price">
                  <Input />
                </Form.Item>
              )}
            </Col>

            <Col span={12}>
              {editData.category && editData.category.length == 1 && (
                <Form.Item name="discount" label="Discount">
                  <Input />
                </Form.Item>
              )}
            </Col>

            <Col span={24}>
              <Form.Item name="slug" label="Slug">
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="url"
                label="Image"
                // rules={[
                //   { required: true, message: "Please add image" },
                // ]}
              >
                <SingleUpload />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="tags" label="Tags">
                <SectionTags
                  tagList={tagList?.split(",")?.filter((item) => item != "")}
                  handleChange={(tags) => setTagList(tags.join(","))}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="material" label="Material" value={materialList}>
                <SectionTags
                  tagList={materialList
                    ?.split(",")
                    ?.filter((item) => item != "")}
                  handleChange={(tags) => setMaterialList(tags.join(","))}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="sort_order" label="Sort order">
                <InputNumber style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}
const mapStateToProps = (state) => ({
  REFRESH: state.levent.CMS_REFRESH,
  unitData: state?.common?.lookup?.unit,
  categoryData: state?.common?.cascade,
  categoryCascade: state?.category?.cascade,
  cmsData: state.cms?.cms,
  cascadeCategory: state.category.cascadeAdd,
});

const mapDispatchToProps = (dispatch) => ({
  getLookup: () => dispatch(fetchLookup()),

  updateCMS: (data, type) => dispatch(putCMS(data, type)),
  addCMS: (data, type) => dispatch(createCMS(data, type)),
  getCMS: (data) => dispatch(fetchCMS(data)),
  addNewItem: () =>
    dispatch({
      type: "CATEGORY_CREATE",
      data: null,
    }),

  getCategories: () => dispatch(fetchData()),

  updateCat: (id, data) => dispatch(updateCategory(id, data)),
  updateSubCat: (id, data) => dispatch(updateSubCategory(id, data)),
  updateLowCat: (id, data) => dispatch(updateLowCategory(id, data)),
  addCategory: (data) => dispatch(createCategory(data)),
  addSubCategory: (data) => dispatch(createSubCategory(data)),
  addLowCategory: (data) => dispatch(createLowCategory(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageCategory);
