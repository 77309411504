import {RCV_BANNER} from '../actions/banner';


const defaultProductPayload = {
};
const banner = (state = {list:[], isEdit : false, product : defaultProductPayload, pagination:{
  current : 1, pageSize :10, total: 1000
}, filter : {} }, action) => {
  switch (action.type) {

    case RCV_BANNER:

      state.list = action.data;
      return state;
    default:
      return state
  }
}
export default banner