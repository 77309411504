import fetch from "cross-fetch";
import {
  requestStart,
  requestFinish,
  getApi,
  postApi,
  updateApi,
} from "./common";
import {
  API_BASE_URL,
  PATH_ETSY_SYNC,
  PATH_SHOPIFY_SYNC,
  PATH_ETSY_GOLD_SYNC,
  PATH_SHOPIFY_TJ_SYNC,
} from "../config";

export function syncEtsy(productId) {
  return async function (dispatch) {
    dispatch({
      type: "SYNC_PRODUCT_START",
      data: { productId: productId },
    });

    const data = await getApi(dispatch, {
      url: `${API_BASE_URL}${PATH_ETSY_SYNC}/${productId}`,
    });

    if (data != null) {
      if (data?.status || data?.listing_id) {
        dispatch({
          type: "NOTIFICATION_SHOW",
          data: {
            title: "Product Synced to etsy",
            type: "success",
            body: "",
          },
        });
        dispatch({ type: "FIRE_EVENT", data: { event: "PRODUCT_REFRESH" } });
      } else {
        dispatch({
          type: "NOTIFICATION_SHOW",
          data: {
            title: "Product Sync to etsy failed",
            type: "error",
            body: data?.message,
          },
        });
      }

      dispatch({
        type: "SYNC_PRODUCT_END",
        data: { productId: productId },
      });
    }
  };
}

export function syncEtsyGold(productId) {
  return async function (dispatch) {
    dispatch({
      type: "SYNC_ETSY_GOLD_PRODUCT_START",
      data: { productId: productId },
    });

    const data = await getApi(dispatch, {
      url: `${API_BASE_URL}${PATH_ETSY_GOLD_SYNC}/${productId}`,
    });

    if (data != null) {
      if (data?.status || data?.listing_id) {
        dispatch({
          type: "NOTIFICATION_SHOW",
          data: {
            title: "Product Synced to etsy gold",
            type: "success",
            body: "",
          },
        });
        dispatch({ type: "FIRE_EVENT", data: { event: "PRODUCT_REFRESH" } });
      } else {
        dispatch({
          type: "NOTIFICATION_SHOW",
          data: {
            title: "Product Sync to etsy gold failed",
            type: "error",
            body: data?.message,
          },
        });
      }

      dispatch({
        type: "SYNC_ETSY_GOLD_PRODUCT_END",
        data: { productId: productId },
      });
    }
  };
}

export function syncShopify(productId) {
  return async function (dispatch) {
    dispatch({
      type: "SYNC_SHOPIFY_PRODUCT_START",
      data: { productId: productId },
    });

    const data = await getApi(dispatch, {
      url: `${API_BASE_URL}${PATH_SHOPIFY_SYNC}/${productId}`,
    });

    if (data != null) {
      if (data?.status) {
        dispatch({
          type: "NOTIFICATION_SHOW",
          data: {
            title: "Product Synced to shopify",
            type: "success",
            body: "",
          },
        });
        dispatch({ type: "FIRE_EVENT", data: { event: "PRODUCT_REFRESH" } });
      } else {
        dispatch({
          type: "NOTIFICATION_SHOW",
          data: {
            title: "Product Sync to shopify failed",
            type: "error",
            body: data?.message,
          },
        });
      }

      dispatch({
        type: "SYNC_SHOPIFY_PRODUCT_END",
        data: { productId: productId },
      });
    }
  };
}

export function syncShopifyTJ(productId) {
  return async function (dispatch) {
    dispatch({
      type: "SYNC_SHOPIFY_TJ_PRODUCT_START",
      data: { productId: productId },
    });

    const data = await getApi(dispatch, {
      url: `${API_BASE_URL}${PATH_SHOPIFY_TJ_SYNC}/${productId}`,
    });

    if (data != null) {
      if (data?.status) {
        dispatch({
          type: "NOTIFICATION_SHOW",
          data: {
            title: "Product Synced to shopify",
            type: "success",
            body: "",
          },
        });
        dispatch({ type: "FIRE_EVENT", data: { event: "PRODUCT_REFRESH" } });
      } else {
        dispatch({
          type: "NOTIFICATION_SHOW",
          data: {
            title: "Product Sync to shopify failed",
            type: "error",
            body: data?.message,
          },
        });
      }

      dispatch({
        type: "SYNC_SHOPIFY_TJ_PRODUCT_END",
        data: { productId: productId },
      });
    }
  };
}
