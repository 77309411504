import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Upload } from "antd";
import { useEffect, useState } from "react";

const ModalPickImage = ({
  visible,
  imageList,
  handleClose,
  beforeImageUpload,
  handleImageChange,
  loading,
}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  return (
    <>
      <Modal
        title="Select Image"
        open={visible}
        destroyOnClose
        onCancel={() => handleClose()}
        footer={[
          <Button
            onClick={() => handleClose()}
            type="default"
            key="cancel"
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>,
          <Upload
            name="file"
            showUploadList={false}
            maxCount={1}
            action={`${process.env.REACT_APP_API_MEDIA_URL}`}
            beforeUpload={beforeImageUpload}
            onChange={handleImageChange}
          >
            <Button
              loading={loading}
              icon={<UploadOutlined />}
              type="default"
              key="upload"
            >
              upload
            </Button>
          </Upload>,
          <Button
            onClick={() => handleClose(selectedImage)}
            type="primary"
            key="add"
            style={{ marginLeft: 8 }}
          >
            Add
          </Button>,
        ]}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {imageList?.length > 0 ? (
            imageList?.map((image) => (
              <div
                onClick={() =>
                  setSelectedImage({
                    name: image.name,
                    url: image.url ?? image?.response?.url,
                    uid: image.uid,
                  })
                }
                onDoubleClick={() =>
                  handleClose({
                    name: image.name,
                    url: image.url ?? image?.response?.url,
                    uid: image.uid,
                  })
                }
                style={{
                  width: "30%",
                  aspectRatio: 1,
                  padding: 12,
                  backgroundColor:
                    selectedImage?.uid === image.uid
                      ? "#d9d9d9"
                      : "transparent",
                }}
              >
                <img
                  src={image.url ?? image?.response?.url}
                  alt={image.name}
                  style={{ width: "100%" }}
                />
              </div>
            ))
          ) : (
            <p>No Images to select</p>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ModalPickImage;
