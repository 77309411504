import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import { Form, Button, Input } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { Table, Space, Popconfirm, Image } from "antd";
import ModalAddVendor from "../component/modal/ModalAddVendor";

import { PageHeader } from "antd";

import {
  createOrUpdateUnit,
  fetchVendor,
  updateVendor,
} from "../actions/category";
import CustomPaginatedTable from "../component/CustomPaginatedTable";

const CMS_TYPE = "Vendor";

function PageVendor({ data, addVendor, getVendors, updateVendor }) {
  const [showVendorModal, setShowVendorModal] = useState({ show: false });

  const [page, setPage] = useState(0);

  const handleVendorSubmit = async (values, isEdit) => {
    console.log(values, isEdit);
    if (values) {
      if (isEdit) await updateVendor(values.uniId, values);
      else await addVendor(values);
    }
    setShowVendorModal({ show: false });
  };

  const columns = [
    {
      title: "Company name",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
    },

    {
      title: "Action",
      key: "action",
      render: (item, record, index) => (
        <Space size="middle">
          <EditOutlined
            style={{ fontSize: 22 }}
            onClick={() => {
              setShowVendorModal({
                show: true,
                formData: { ...item, uniId: item?.user_id },
              });
            }}
          />

          <Popconfirm
            title="Are you sure to delete this?"
            onConfirm={() =>
              updateVendor(item.user_id, {
                ...item,
                status: false,
              })
            }
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ fontSize: 22, color: "red" }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div className="site-page-header-ghost-wrapper">
      <PageHeader
        title={`${CMS_TYPE}s`}
        className="site-page-header"
        extra={[
          <Button
            key="1"
            type="primary"
            onClick={() => {
              setShowVendorModal({ show: true });
            }}
          >
            Add
          </Button>,
        ]}
      ></PageHeader>
      <CustomPaginatedTable
        columns={columns}
        getData={getVendors}
        setPage={setPage}
        page={page}
        showLimitChanger
        dataSource={data}
        showIndexColumn
      />

      <ModalAddVendor
        visible={showVendorModal?.show}
        formData={showVendorModal?.formData}
        handleClose={handleVendorSubmit}
      />
    </div>
  );
}
const mapStateToProps = (state) => ({
  data: state.vendor.data,
});

const mapDispatchToProps = (dispatch) => ({
  updateVendor: (id, data) => dispatch(updateVendor(id, data)),
  getVendors: (data) => dispatch(fetchVendor(data)),
  addVendor: (data) => dispatch(createOrUpdateUnit(data, true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageVendor);
