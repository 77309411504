const hasLocalStorage = () => {
  return !!window.localStorage;
};

export const load_storage = (key) => {
  if (hasLocalStorage()) {
    const stritem = localStorage.getItem(key);
    if (stritem && stritem !== "") {
      try {
        return JSON.parse(stritem);
      } catch (error) {
        // console.log('json parse error :', stritem);
      }
    }
  }

  return null;
};

export function save_storage(key, value) {
  if (hasLocalStorage()) {
    localStorage.setItem(key, JSON.stringify(value));
  }
}

export function remove_storage(key) {
  if (hasLocalStorage()) {
    localStorage.removeItem(key);
  }
}

export function clear_storage() {
  if (hasLocalStorage()) {
    localStorage.clear();
  }
}
