import React, { useEffect, useState } from "react";
import { Form, Row, Col, Input, AutoComplete } from "antd";

import { Modal, Button, Switch } from "antd";
import { connect } from "react-redux";
import { Cascader, Select } from "antd";
import { SectionTags } from "../SectionTags";

const { Option } = Select;

const ModalFilterProduct = ({
  visible,
  handleClose,
  cascadeCategory,
  formData,
  cmsData,
}) => {
  const [form] = Form.useForm();

  const [options, setOptions] = useState([]);

  const onSearch = (searchText) => {
    setOptions(!searchText ? [] : [{ value: searchText }]);
  };

  const onFinish = (values) => {
    handleClose(form.getFieldsValue());
  };

  const onOk = (values) => {
    handleClose(form.getFieldsValue());
  };

  useEffect(() => {
    if (visible) {
      console.log(formData);
      if (Object.keys(formData).length === 0) form.resetFields();
      else {
        form.setFieldsValue(formData);
      }
    }
  }, [visible]);

  return (
    <>
      <Modal
        title="Search Product"
        visible={visible}
        onOk={onOk}
        onCancel={() => handleClose()}
      >
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
        >
          <Form.Item name="query" label="keywords">
            <Input placeholder="name, model, description etc" />
          </Form.Item>
          <Form.Item name="tags" label="Tags">
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="Please select"
            >
              {cmsData
                ?.filter((i) => i.status && i.entity_id === "dim.product.tags")
                ?.map((item) => (
                  <Option key={item.cms_id} value={item.data}>
                    {item.data}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item name="model" label="Model">
            <Select
              options={options}
              style={{
                width: "100%",
              }}
              onSelect={() => setOptions([])}
              mode="multiple"
              allowClear
              onSearch={onSearch}
              placeholder="input here"
              notFoundContent={""}
            />

            {/* <Form.Item name="model" label="Model">
            <SectionTags
              tagList={modelList}
              handleChange={(models) => {
                form.setFieldValue("model", models.join(","));
                setModelList(models);
              }}
            /> */}
          </Form.Item>

          <Form.Item name="sku" label="Sku">
            <Input placeholder="sku" />
          </Form.Item>

          <Form.Item
            name="only_deactive"
            label="Inactive"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item name="category" label="Category">
            <Cascader options={cascadeCategory} changeOnSelect />
          </Form.Item>

          <Col span={4}>
            <Button
              style={{ marginTop: 30, display: "none" }}
              type="primary"
              htmlType="submit"
              size={"medium"}
            />
          </Col>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  products: state.products,
  category: state.category.data,
  cascadeCategory: state.category.cascade,
  cmsData: state.cms?.cms,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ModalFilterProduct);
