import { set } from "local-storage";

const unit = (state = {
  data:[],
  selected:{},

  editData:{
    name: "",
    id: false  }

}, action) => {
  switch (action.type) {

    case 'UNIT_LIST':

      return {
        ...state,
        data : action.data
      };

        
    default:
      return state
  }
}
export default unit