import fetch from "cross-fetch";
import {
  requestStart,
  requestFinish,
  getApi,
  postApi,
  updateApi,
  deleteApi,
} from "./common";
import {
  API_BASE_URL,
  PATH_CATEGORY,
  PATH_CAT,
  PATH_LOWCAT,
  PATH_SUBCAT,
  PATH_LOOKUP,
  PATH_UNIT,
  PATH_VENDOR,
  PATH_TYPE,
  PATH_CMS,
} from "../config";
import { objectToQueryParams } from "../utils/mappers";

export const GET_CATEGORY = "GET_CATEGORY";
export const CREATE_OR_UPDATE_CATEGORY = "CREATE_OR_UPDATE_CATEGORY";

function recvData(json) {
  return {
    type: GET_CATEGORY,
    data: json.category,
    receivedAt: Date.now(),
  };
}

function postData(isCreate, json) {
  return {
    type: CREATE_OR_UPDATE_CATEGORY,
    data: json,
    message: isCreate,
    receivedAt: Date.now(),
  };
}

export function fetchData(query) {
  return function (dispatch) {
    dispatch(requestStart());

    return fetch(`${API_BASE_URL}${PATH_LOOKUP}`, {
      method: "GET",
    })
      .then(
        (response) => response.json(),

        (error) => {
          throw new Error("Whoops!");
        }
      )
      .then((json) => {
        dispatch(recvData(json));

        dispatch(requestFinish());

        dispatch({
          type: "UNIT_LIST",
          data: json.unit,
          receivedAt: Date.now(),
        });

        dispatch({
          type: "TYPE_LIST",
          data: json?.type,
          receivedAt: Date.now(),
        });

        dispatch({
          type: "NOTIFICATION_HIDE",
          data: {
            title: "",
            type: "success",
            body: "",
          },
        });
      })
      .catch((error) => {
        dispatch(requestFinish());
      });
  };
}

export function fetchVendor(reqData) {
  return function (dispatch) {
    dispatch(requestStart());

    const QUERY_PARAMS = objectToQueryParams(reqData);

    return fetch(`${API_BASE_URL}${PATH_VENDOR}${QUERY_PARAMS}`, {
      method: "GET",
    })
      .then(
        (response) => response.json(),

        (error) => {
          throw new Error("Whoops!");
        }
      )
      .then((json) => {
        dispatch(requestFinish());
        console.log(json);
        dispatch({
          type: "VENDOR_LIST",
          data: json,
          receivedAt: Date.now(),
        });

        dispatch({
          type: "NOTIFICATION_HIDE",
          data: {
            title: "",
            type: "success",
            body: "",
          },
        });
      })
      .catch((error) => {
        dispatch(requestFinish());
      });
  };
}

export function updateVendor(uniId, reqData) {
  return async function (dispatch) {
    const data = await updateApi(dispatch, {
      url: `${API_BASE_URL}${PATH_VENDOR}/${uniId}`,
      body: reqData,
    });

    console.log("vendor updated", data);
    if (data != null) {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: "Vendor updated",
          type: "success",
          body: "",
        },
      });
      dispatch(fetchVendor());
    }
  };
}

export function createOrUpdateUnit(reqData, type = false) {
  return async function (dispatch) {
    const data = await postApi(dispatch, {
      url: `${API_BASE_URL}${type ? PATH_VENDOR : PATH_UNIT}`,
      body: reqData,
    });

    if (data != null) {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: `${type ? "Vendor" : "Unit"} Created`,
          type: "success",
          body: "",
        },
      });

      dispatch(type ? fetchVendor() : fetchData());
    } else {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: `Cannot create ${type ? "Vendor" : "Unit"}`,
          type: "error",
          body: "",
        },
      });
    }
  };
}

export function updateCms(uniId, reqData) {
  return async function (dispatch) {
    const data = await updateApi(dispatch, {
      url: `${API_BASE_URL}${PATH_VENDOR}/${uniId}`,
      body: reqData,
    });

    console.log("vendor updated", data);
    if (data != null) {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: "Vendor updated",
          type: "success",
          body: "",
        },
      });
      dispatch(fetchVendor());
    }
  };
}

export function fetchCms(reqData) {
  return function (dispatch) {
    dispatch(requestStart());
    const QUERY_PARAMS = objectToQueryParams(reqData);

    return fetch(`${API_BASE_URL}${PATH_CMS}${QUERY_PARAMS}`, {
      method: "GET",
    })
      .then(
        (response) => response.json(),

        (error) => {
          throw new Error("Whoops!");
        }
      )
      .then((json) => {
        dispatch(requestFinish());

        dispatch({
          type: "CMS_LIST",
          data: json,
          receivedAt: Date.now(),
        });

        dispatch({
          type: "NOTIFICATION_HIDE",
          data: {
            title: "",
            type: "success",
            body: "",
          },
        });
      })
      .catch((error) => {
        dispatch(requestFinish());
      });
  };
}

export function fetchEntityCms(reqData, type = "") {
  return function (dispatch) {
    dispatch(requestStart());
    const QUERY_PARAMS = objectToQueryParams(reqData);

    return fetch(`${API_BASE_URL}${PATH_CMS}/${type}${QUERY_PARAMS}`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Whoops!");
        } else return response.json();
      })
      .then((json) => {
        dispatch(requestFinish());

        if (json) {
          dispatch({
            type: "ENTITY_LIST",
            data: json,
            receivedAt: Date.now(),
          });
        }
        dispatch({
          type: "NOTIFICATION_HIDE",
          data: {
            title: "",
            type: "success",
            body: "",
          },
        });
      })
      .catch((error) => {
        dispatch(requestFinish());
      });
  };
}

export function addCMS(reqData, entity_id) {
  return async function (dispatch) {
    const data = await postApi(dispatch, {
      url: `${API_BASE_URL}${PATH_CMS}`,
      body: { data: reqData, entity_id },
    });

    if (data != null) {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: `CMS Created`,
          type: "success",
          body: "",
        },
      });

      dispatch({ type: "FIRE_EVENT", data: { event: "CMS_REFRESH" } });
    } else {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: `Cannot create CMS}`,
          type: "error",
          body: "",
        },
      });
    }
  };
}

export function deleteCMS(reqData) {
  return async function (dispatch) {
    const data = await deleteApi(dispatch, {
      url: `${API_BASE_URL}${PATH_CMS}/${reqData}`,
    });

    if (data != null) {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: `CMS deleted`,
          type: "success",
          body: "",
        },
      });

      dispatch({ type: "FIRE_EVENT", data: { event: "CMS_REFRESH" } });
    } else {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: `Cannot delete CMS`,
          type: "error",
          body: "",
        },
      });
    }
  };
}

export function deleteUnit(reqData, type = false) {
  return async function (dispatch) {
    const data = await deleteApi(dispatch, {
      url: `${API_BASE_URL}${type ? PATH_VENDOR : PATH_UNIT}/${reqData}`,
    });

    if (data != null) {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: `${type ? "Vendor" : "Unit"} deleted`,
          type: "success",
          body: "",
        },
      });

      dispatch(fetchData());
    } else {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: `Cannot delete ${type ? "Type" : "Unit"}`,
          type: "error",
          body: "",
        },
      });
    }
  };
}

export function createOrUpdate(isCreate, data) {
  return function (dispatch) {
    dispatch(requestStart());

    return fetch(`${API_BASE_URL}${PATH_CATEGORY}`, {
      method: isCreate ? "POST" : "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(
        (response) => response.json(),

        (error) => {
          throw new Error("Whoops!");
        }
      )
      .then((json) => {
        dispatch(postData(isCreate, json));

        dispatch(requestFinish());
      })
      .catch((error) => {
        dispatch(requestFinish());
      });
  };
}

export function createCategory(reqData) {
  return async function (dispatch) {
    const data = await postApi(dispatch, {
      url: `${API_BASE_URL}${PATH_CAT}`,
      body: reqData,
    });

    if (data != null) {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: "Category Created",
          type: "success",
          body: "",
        },
      });

      dispatch(fetchData());
    }
  };
}

export function updateCategory(uniId, reqData) {
  return async function (dispatch) {
    const data = await updateApi(dispatch, {
      url: `${API_BASE_URL}${PATH_CAT}/${uniId}`,
      body: reqData,
    });

    console.log("user updated", data);
    if (data != null) {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: "Category Update",
          type: "success",
          body: "",
        },
      });
      dispatch(fetchData());
    }
  };
}

export function createSubCategory(reqData) {
  return async function (dispatch) {
    const data = await postApi(dispatch, {
      url: `${API_BASE_URL}${PATH_SUBCAT}`,
      body: reqData,
    });

    if (data != null) {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: "Sub Category Created",
          type: "success",
          body: "",
        },
      });

      dispatch(fetchData());
    }
  };
}

export function updateSubCategory(uniId, reqData) {
  return async function (dispatch) {
    const data = await updateApi(dispatch, {
      url: `${API_BASE_URL}${PATH_SUBCAT}/${uniId}`,
      body: reqData,
    });

    console.log("user updated", data);
    if (data != null) {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: "Sub Category Update",
          type: "success",
          body: "",
        },
      });
      dispatch(fetchData());
    }
  };
}

export function createLowCategory(reqData) {
  return async function (dispatch) {
    const data = await postApi(dispatch, {
      url: `${API_BASE_URL}${PATH_LOWCAT}`,
      body: reqData,
    });

    if (data != null) {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: "Low Category Created",
          type: "success",
          body: "",
        },
      });

      dispatch(fetchData());
    }
  };
}

export function updateLowCategory(uniId, reqData) {
  return async function (dispatch) {
    const data = await updateApi(dispatch, {
      url: `${API_BASE_URL}${PATH_LOWCAT}/${uniId}`,
      body: reqData,
    });

    console.log("user updated", data);
    if (data != null) {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: "Low Category Update",
          type: "success",
          body: "",
        },
      });
      dispatch(fetchData());
    }
  };
}
