import React, { useEffect, useState } from "react";
import { Form, Row, Col, Input, Tag } from "antd";

import { Modal, Button, Switch } from "antd";
import { connect } from "react-redux";
import {
  Cascader,
  Select,
  Checkbox,
  AutoComplete,
  Upload,
  Space,
  Divider,
} from "antd";

const ModalBulkUpdate = ({
  visible,
  handleClose,
  cascadeCategory,
  formData,
}) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    handleClose(form.getFieldsValue());
  };

  return (
    <>
      <Modal
        title="Bulk Update"
        visible={visible}
        onOk={form.submit}
        onCancel={() => handleClose()}
      >
        {Object.keys(formData).length > 0 && (
          <p>
            Filters Applied:
            {Object.entries(formData).map(([key, value]) => (
              <Tag>
                {key}: {value}
              </Tag>
            ))}
          </p>
        )}
        <Form
          form={form}
          name="advanced_search"
          className="ant-advanced-search-form"
          onFinish={onFinish}
        >
          <h2>Updates</h2>
          <Form.Item
            name="field"
            label="Field"
            rules={[
              {
                required: true,
                message: "Missing Field ",
              },
            ]}
          >
            <Input placeholder="field" />
          </Form.Item>
          <Form.Item
            name="operator"
            label="Operator"
            rules={[
              {
                required: true,
                message: "Missing operator ",
              },
            ]}
          >
            <Select
              options={[
                { value: "append", label: "append" },
                { value: "equal", label: "equal" },
              ]}
              changeOnSelect
            />
          </Form.Item>
          <Form.Item
            name="values"
            label="Value"
            rules={[
              {
                required: true,
                message: "Missing value ",
              },
            ]}
          >
            <Input placeholder="value" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  products: state.products,
  category: state.category.data,
  cascadeCategory: state.category.cascade,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ModalBulkUpdate);
