import React, { useState, useEffect, useRef } from "react";

import { connect } from "react-redux";

import {
  Button,
  Input,
  Select,
  List,
  Cascader,
  Table,
  InputNumber,
  TreeSelect,
  Form,
} from "antd";

import { Tag, Space, Switch } from "antd";

import { PageHeader } from "antd";
import { useHistory } from "react-router-dom";

import {
  syncEtsy,
  syncShopify,
  syncEtsyGold,
  syncShopifyTJ,
} from "../actions/sync";
import {
  fetchData as fetchCategory,
  createOrUpdateUnit,
  fetchVendor,
  fetchCms,
} from "../actions/category";

import {
  ArrowLeftOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  SyncOutlined,
} from "@ant-design/icons";

import {
  fetchData,
  putData,
  createData,
  putBulkData,
} from "../actions/product";
import ModalFilterProduct from "../component/modal/ModalFilterProduct";
import CustomPaginatedTable from "../component/CustomPaginatedTable";

import {
  load_storage,
  remove_storage,
  save_storage,
} from "../utils/localstorage";
import { render } from "@testing-library/react";
import ModalSync from "../component/modal/ModalSync";
const { Option } = Select;

const getCategoryArray = (data) => {
  let cat = [];
  if (data.category.length > 0) {
    cat.push(data.category[0].cat_id);
  }

  if (data.subcategory.length > 0) {
    cat.push(data.subcategory[0].subcat_id);
  }

  if (data.lowcategory.length > 0) {
    cat.push(data.lowcategory[0].lowcat_id);
  }

  return cat;
};

const getCategoryUniArray = (data) => {
  let cat = [];
  if (data?.category.length > 0) {
    cat.push(data.category[0].uni_id);
  }

  if (data.subcategory.length > 0) {
    cat.push(data.subcategory[0].uni_id);
  }

  if (data.lowcategory.length > 0) {
    cat.push(data.lowcategory[0].uni_id);
  }

  return cat;
};

const getFirstVariantData = (data) => {
  let variant = {};
  if (data?.variant?.length >= 1) {
    variant = data.variant[0];
  }
  return variant;
};

const getProductMaterial = (data) => {
  return [];
  if (data.material.length == 0) return [];
  let tags = data.material[0].tags.split(",");

  console.log(data);
  return tags;
};

const getVariantData = (data) => {
  // if (productData.variant.length == 1) return [];
  let variant = data?.variant?.filter(
    (item) =>
      (item.title_1 && item.title_1 != "" && item.title_1 != null) || true
  );

  console.log(variant);

  return variant;
};

function PageProduct({
  data,
  updateProduct,
  getData,
  PRODUCT_REFRESH,
  getCategories,
  getVendors,
  cascadeCategory,
  vendorData,
  unitData,
  cmsData,
  getCms,
  syncOnEtsy,
  syncOnEtsyGold,
  syncOnShopify,
  syncOnShopifyTJ,
}) {
  const [showFilter, toggleFilter] = React.useState(false);
  const [updated, setUpdated] = useState(false);
  const [percentValue, setPercentValue] = useState();

  const tagRef = useRef([]);

  const getRowClassName = (record) => {
    if (!updated) return "";
    if (!record.updateStatus) {
      return selectedRowKeys.length > 0 &&
        selectedRowKeys.includes(record.productId) ? (
        <>
          <p>{record.updateMessage}</p>
          <CloseOutlined
            style={{ color: "red", fontSize: 20, fontWeight: "bold" }}
          />
        </>
      ) : (
        ""
      );
    } else {
      return (
        <CheckOutlined
          style={{ color: "green", fontSize: 20, fontWeight: "bold" }}
        />
      );
    }
  };

  const expandedRowRender = (parentRecord, parentIndex) => {
    const columns = [
      {
        title: "Variant",
        key: "variant",
        render: (item) => {
          return `${item.title_1} ${
            !!item.title_2 ? `- ${item.title_2}` : ""
          } ${!!item.title_3 ? `- ${item.title_3}` : ""}`;
        },
      },
      {
        title: "Price",
        dataIndex: "mrp",
        key: "variant_mrp",
        render: (item, record, index) => {
          return (
            <InputNumber
              value={item}
              onChange={(e) =>
                handleInputChange(e, parentRecord.key, "variant", index, "mrp")
              }
            />
          );
        },
        filterIcon: <EditOutlined style={{ fontSize: 16, color: "black" }} />,
        filterDropdown: () => (
          <div style={{ padding: 12 }}>
            <p>Price</p>
            <InputNumber
              style={{ marginBottom: 14, width: "100%" }}
              onChange={(e) =>
                handleInputChange(
                  e,
                  parentRecord.key,
                  "variant",
                  null,
                  "mrp",
                  true
                )
              }
            />
            <p>Change by percent</p>
            <Button
              onClick={() => {
                if (percentValue)
                  handleInputChange(
                    10,
                    parentRecord.key,
                    "variant",
                    null,
                    "mrp",
                    true,
                    1 - percentValue / 100
                  );
              }}
            >
              -
            </Button>
            <InputNumber
              value={percentValue}
              onChange={(e) => setPercentValue(e)}
              style={{ margin: "0px 4px" }}
            />
            <Button
              onClick={() => {
                if (percentValue)
                  handleInputChange(
                    10,
                    parentRecord.key,
                    "variant",
                    null,
                    "mrp",
                    true,
                    1 + percentValue / 100
                  );
              }}
            >
              +
            </Button>
          </div>
        ),
      },
      {
        title: "Discount",
        dataIndex: "discount_percent",
        key: "variant_discount",
        render: (item, record, index) => (
          <InputNumber
            value={item}
            onChange={(e) =>
              handleInputChange(
                e.target.value,
                parentRecord.key,
                "variant",
                index,
                "discount_percent"
              )
            }
          />
        ),
        filterIcon: <EditOutlined style={{ fontSize: 16, color: "black" }} />,
        filterDropdown: () => (
          <div style={{ padding: 12 }}>
            <p>Discount</p>
            <InputNumber
              style={{ marginBottom: 14, width: "100%" }}
              onChange={(e) =>
                handleInputChange(
                  e,
                  parentRecord.key,
                  "variant",
                  null,
                  "discount_percent",
                  true
                )
              }
            />
            <p>Change by percent</p>
            <Button
              onClick={() => {
                if (percentValue)
                  handleInputChange(
                    10,
                    parentRecord.key,
                    "variant",
                    null,
                    "discount_percent",
                    true,
                    1 - percentValue / 100
                  );
              }}
            >
              -
            </Button>
            <InputNumber
              value={percentValue}
              onChange={(e) => setPercentValue(e)}
              style={{ margin: "0px 4px" }}
            />
            <Button
              onClick={() => {
                if (percentValue)
                  handleInputChange(
                    10,
                    parentRecord.key,
                    "variant",
                    null,
                    "discount_percent",
                    true,
                    1 + percentValue / 100
                  );
              }}
            >
              +
            </Button>
          </div>
        ),
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "variant_quantity",
        render: (item, record, index) => (
          <InputNumber
            value={item}
            onChange={(e) =>
              handleInputChange(
                e.target.value,
                parentRecord.key,
                "variant",
                index,
                "quantity"
              )
            }
          />
        ),
        filterIcon: <EditOutlined style={{ fontSize: 16, color: "black" }} />,
        filterDropdown: () => (
          <div style={{ padding: 12 }}>
            <p>Quantity</p>
            <InputNumber
              style={{ marginBottom: 14, width: "100%" }}
              onChange={(e) =>
                handleInputChange(
                  e,
                  parentRecord.key,
                  "variant",
                  null,
                  "quantity",
                  true
                )
              }
            />
            <p>Change by percent</p>
            <Button
              onClick={() => {
                if (percentValue)
                  handleInputChange(
                    10,
                    parentRecord.key,
                    "variant",
                    null,
                    "quantity",
                    true,
                    1 - percentValue / 100
                  );
              }}
            >
              -
            </Button>
            <InputNumber
              value={percentValue}
              onChange={(e) => setPercentValue(e)}
              style={{ margin: "0px 4px" }}
            />
            <Button
              onClick={() => {
                if (percentValue)
                  handleInputChange(
                    10,
                    parentRecord.key,
                    "variant",
                    null,
                    "quantity",
                    true,
                    1 + percentValue / 100
                  );
              }}
            >
              +
            </Button>
          </div>
        ),
      },
      {
        title: "Min Quantity",
        dataIndex: "q_notify",
        key: "variant_min",
        render: (item, record, index) => (
          <InputNumber
            value={item}
            onChange={(e) =>
              handleInputChange(
                e.target.value,
                parentRecord.key,
                "variant",
                index,
                "q_notify"
              )
            }
          />
        ),
        filterIcon: <EditOutlined style={{ fontSize: 16, color: "black" }} />,
        filterDropdown: () => (
          <div style={{ padding: 12 }}>
            <p>Min Quantity</p>
            <InputNumber
              style={{ marginBottom: 14, width: "100%" }}
              onChange={(e) =>
                handleInputChange(
                  e,
                  parentRecord.key,
                  "variant",
                  null,
                  "q_notify",
                  true
                )
              }
            />
            <p>Change by percent</p>
            <Button
              onClick={() => {
                if (percentValue)
                  handleInputChange(
                    10,
                    parentRecord.key,
                    "variant",
                    null,
                    "q_notify",
                    true,
                    1 - percentValue / 100
                  );
              }}
            >
              -
            </Button>
            <InputNumber
              value={percentValue}
              onChange={(e) => setPercentValue(e)}
              style={{ margin: "0px 4px" }}
            />
            <Button
              onClick={() => {
                if (percentValue)
                  handleInputChange(
                    10,
                    parentRecord.key,
                    "variant",
                    null,
                    "q_notify",
                    true,
                    1 + percentValue / 100
                  );
              }}
            >
              +
            </Button>
          </div>
        ),
      },
      {
        title: "SKU",
        dataIndex: "sku",
        key: "variant_sku",
        render: (item, record, index) => (
          <Input
            value={item}
            onChange={(e) =>
              handleInputChange(
                e.target.value,
                parentRecord.key,
                "variant",
                index,
                "sku"
              )
            }
          />
        ),
      },
      {
        title: "Type 1",
        dataIndex: "var_type_1",
        key: "var_type_1",
        render: (item, record, index) => (
          <Select
            style={{ width: "100%" }}
            value={item}
            placeholder="Type"
            onChange={(e) =>
              handleInputChange(
                e,
                parentRecord.key,
                "variant",
                index,
                "var_type_1"
              )
            }
          >
            {cmsData
              ?.filter(
                (i) => i.status && i.entity_id === "dim.product.variant.option"
              )
              ?.map((item) => (
                <Option key={item.cms_id} value={item.data}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>{item.data}</span>
                  </div>
                </Option>
              ))}
          </Select>
        ),
      },
      {
        title: "Title 1",
        dataIndex: "title_1",
        key: "title_1",
        render: (item, record, index) => (
          <Input
            value={item}
            onChange={(e) =>
              handleInputChange(
                e.target.value,
                parentRecord.key,
                "variant",
                index,
                "title_1"
              )
            }
          />
        ),
      },
      {
        title: "Type 2",
        dataIndex: "var_type_2",
        key: "var_type_2",
        render: (item, record, index) => (
          <Select
            style={{ width: "100%" }}
            value={item}
            placeholder="Type"
            onChange={(e) =>
              handleInputChange(
                e,
                parentRecord.key,
                "variant",
                index,
                "var_type_2"
              )
            }
          >
            {cmsData
              ?.filter(
                (i) => i.status && i.entity_id === "dim.product.variant.option"
              )
              ?.map((item) => (
                <Option key={item.cms_id} value={item.data}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>{item.data}</span>
                  </div>
                </Option>
              ))}
          </Select>
        ),
      },
      {
        title: "Title 2",
        dataIndex: "title_2",
        key: "title_2",
        render: (item, record, index) => (
          <Input
            value={item}
            onChange={(e) =>
              handleInputChange(
                e.target.value,
                parentRecord.key,
                "variant",
                index,
                "title_2"
              )
            }
          />
        ),
      },
      {
        title: "Type 3",
        dataIndex: "var_type_3",
        key: "var_type_3",
        render: (item, record, index) => (
          <Select
            style={{ width: "100%" }}
            value={item}
            placeholder="Type"
            onChange={(e) =>
              handleInputChange(
                e,
                parentRecord.key,
                "variant",
                index,
                "var_type_3"
              )
            }
          >
            {cmsData
              ?.filter(
                (i) => i.status && i.entity_id === "dim.product.variant.option"
              )
              ?.map((item) => (
                <Option key={item.cms_id} value={item.data}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>{item.data}</span>
                  </div>
                </Option>
              ))}
          </Select>
        ),
      },
      {
        title: "Title 3",
        dataIndex: "title_3",
        key: "title_3",
        render: (item, record, index) => (
          <Input
            value={item}
            onChange={(e) =>
              handleInputChange(
                e.target.value,
                parentRecord.key,
                "variant",
                index,
                "title_3"
              )
            }
          />
        ),
      },
    ];
    const data = parentRecord.variant ?? [];

    return data.length > 0 ? (
      <Table
        columns={columns?.filter((i) =>
          includedTableVariantColumns.includes(i.key)
        )}
        dataSource={data}
        pagination={false}
        scroll={{ x: 1500 }}
      />
    ) : (
      "No variants"
    );
  };

  const [page, setPage] = useState(0);
  const [editData, setEditData] = useState();
  const [showModalSync, setShowModalSync] = React.useState(false);
  const [modalData, setModalData] = useState({});

  const handleProductSync = async (data, type) => {
    await onSubmit(data.uniId);
    if (type === "etsy_listing_id") {
      syncOnEtsy(data.uniId);
    } else if (type === "etsy_gold_listing_id") {
      syncOnEtsyGold(data.uniId);
    } else if (type === "shopify_id") {
      syncOnShopify(data.uniId);
    } else if (type === "shopify_tj_id") {
      syncOnShopifyTJ(data.uniId);
    }
  };

  const history = useHistory();

  const selectedProductKeys = load_storage("selectedProductKeys") ?? [];

  const onEditClicked = (data) => {
    const productData = data;

    return {
      discount: 0,
      key: productData.product_id,
      productId: productData.product_id,
      name: productData.name,
      model: productData.model,
      sku: productData.sku,
      description: productData.description,
      category: getCategoryArray(data),
      categoryUni: getCategoryUniArray(data),
      price: getFirstVariantData(data)["mrp"],
      discount: getFirstVariantData(data)["discount_percent"],
      variant: getVariantData(data),
      tags: !!productData?.tags?.[0] ? productData?.tags : [{}],
      material: getProductMaterial(data),
      unit: productData.unit_meta.unit_id,
      slug: productData.slug,
      images: productData.images,
      uniId: productData.product_id,
      isFormEdit: true,
      etsy_gold_listing_id: productData.etsy_gold_listing_id,
      etsy_listing_id: productData.etsy_listing_id,
      shopify_id: productData.shopify_id,
      shopify_tj_id: productData.shopify_tj_id,
      status: productData.status,
      vendor_id: productData?.vendor?.find((v) => v.status)?.vendor_id,
      product_type: productData.product_type,
    };
  };

  const onFinish = (values) => {
    let productData = values;

    let removedVariant =
      getVariantData(editData)
        ?.filter(
          (item) =>
            values.variant.filter(
              (i) =>
                i.product_to_variants_id &&
                i.product_to_variants_id == item.product_to_variants_id
            ).length == 0
        )
        .map((item) => {
          return {
            ...item,
            status: false,
          };
        }) ?? [];
    console.log("removed variant", removedVariant);
    let finalVariants = values?.variant
      ? [...values?.variant, ...removedVariant]
      : [...removedVariant];

    let payloadProduct = {
      ...values,
      variant: finalVariants,
      tags: !!values.tags ? values.tags : [],
      ...{
        [values.category[0] != null]: {
          category: [
            {
              ...productData?.category?.[0],
              cat_id: values.category[0] ?? null,
              uni_id: productData?.categoryUni?.[0] ?? null,
            },
          ],
        },
      }.true,
      ...{
        [values.category[1] != null]: {
          subcategory: [
            {
              ...productData?.subcategory?.[0],

              subcat_id: values.category[1],
              uni_id: productData?.categoryUni?.[1] ?? null,
            },
          ],
        },
      }.true,
      ...{
        [values.category[2] != null]: {
          lowcategory: [
            {
              ...productData?.lowcategory?.[0],

              lowcat_id: values.category[2] ?? null,
              uni_id: productData?.categoryUni?.[2] ?? null,
            },
          ],
        },
      }.true,
    };

    if (!payloadProduct.discount) {
      payloadProduct = {
        ...payloadProduct,
        discount: 0,
        product_id: productData?.productId,
      };
    }

    return payloadProduct;
  };

  useEffect(() => {
    if (!updated) {
      const commanTags = [];
      const formattedData = data
        .filter((product) => {
          if (selectedProductKeys && selectedProductKeys.length > 0) {
            return selectedProductKeys.includes(product.product_id);
          } else {
            return true;
          }
        })
        .map((product) => onEditClicked(product));
      formattedData.forEach((i) => {
        if (!!i.tags[0]?.tags) {
          commanTags.push(i.tags[0]?.tags?.split(","));
        }
      });
      let flatArray = [];
      if (commanTags.length > 0) {
        flatArray = commanTags.flat();

        tagRef.current = [...new Set(flatArray)];
      }
      setEditData(formattedData);
    }
  }, [data]);

  const savedTableColumns = load_storage("product-columns");
  const savedTableVariantColumns = load_storage("variant-columns");

  const [includedTableColumns, setIncludedTableColumns] = React.useState(
    savedTableColumns ?? []
  );
  const [includedTableVariantColumns, setIncludedTableVariantColumns] =
    React.useState(savedTableVariantColumns ?? []);
  useEffect(() => {
    if (!savedTableColumns) {
      setIncludedTableColumns([
        "name",
        "vendor",
        "unit",
        "product_type",
        "category",
        "model",
        "sku",
        "tags",
        "status",
        "sync",
      ]);
      save_storage("product-columns", [
        "name",
        "vendor",
        "unit",
        "product_type",
        "category",
        "model",
        "sku",
        "tags",
        "status",
        "sync",
      ]);
    }

    if (!savedTableVariantColumns) {
      setIncludedTableVariantColumns([
        "variant_mrp",
        "variant_discount",
        "variant_quantity",
        "variant_min",
        "variant_sku",
        "var_type_1",
        "title_1",
        "var_type_2",
        "title_2",
        "var_type_3",
        "title_3",
      ]);
      save_storage("variant-columns", [
        "variant_mrp",
        "variant_discount",
        "variant_quantity",
        "variant_min",
        "variant_sku",
        "var_type_1",
        "title_1",
        "var_type_2",
        "title_2",
        "var_type_3",
        "title_3",
      ]);
    }
  }, [savedTableColumns, savedTableVariantColumns]);

  const [filters, setFilters] = React.useState({});

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const toggleFilterDialog = (data) => {
    console.log(data);

    if (data) {
      save_storage("productFilter", data);
      remove_storage("selectedProductKeys");
      setFilters(data);
      setPage(0);
    }

    toggleFilter(false);
  };

  useEffect(() => {
    const storedFilters = load_storage("productFilter");
    if (storedFilters) setFilters(storedFilters);
  }, [PRODUCT_REFRESH]);

  useEffect(() => {
    getCategories();
    getVendors();
    getCms({ limit: 1000 });
  }, []);

  const handleInputChange = (
    value,
    key,
    dataIndex,
    nestedIndex,
    nestedKey,
    nestedall,
    multiplier
  ) => {
    let newData = [...editData];
    if (key) {
      const index = newData.findIndex((item) => key === item.key);
      if (nestedKey) {
        if (nestedall) {
          newData[index][dataIndex] = newData[index][dataIndex].map((i) => ({
            ...i,
            [nestedKey]: multiplier
              ? Math.ceil(multiplier * i[nestedKey])
              : value,
          }));
        } else
          newData[index][dataIndex][nestedIndex][nestedKey] = multiplier
            ? Math.ceil(
                multiplier * newData[index][dataIndex][nestedIndex][nestedKey]
              )
            : value;
      } else {
        newData[index][dataIndex] = value;
      }
    } else {
      if (selectedRowKeys.length === 0) {
        if (nestedKey) {
          newData = newData.map((i) => ({
            ...i,
            [dataIndex]: i[dataIndex]?.map((i) => ({
              ...i,
              [nestedKey]: multiplier
                ? Math.ceil(multiplier * i[nestedKey])
                : value,
            })),
          }));
        } else {
          newData = newData.map((i) => ({ ...i, [dataIndex]: value }));
        }
      } else {
        if (nestedKey) {
          newData = newData.map((i) => {
            if (selectedRowKeys.includes(i.productId))
              return {
                ...i,
                [dataIndex]: i[dataIndex]?.map((i) => ({
                  ...i,
                  [nestedKey]: multiplier
                    ? Math.ceil(multiplier * i[nestedKey])
                    : value,
                })),
              };
            else {
              return i;
            }
          });
        } else {
          newData = newData.map((i) => {
            if (selectedRowKeys.includes(i.productId))
              return { ...i, [dataIndex]: value };
            else {
              return i;
            }
          });
        }
      }
    }
    setEditData(newData);
  };

  const onSubmit = async (product_id) => {
    setUpdated(false);
    let finalData;
    if (product_id) {
      const singleData = editData.find(
        (product) => (product.productId = product_id)
      );
      if (singleData) {
        finalData = [singleData].map((record) => onFinish(record));
      } else {
        return;
      }
    } else if (
      selectedRowKeys.length == 0 ||
      selectedRowKeys.length == editData.length
    ) {
      finalData = editData.map((record) => onFinish(record));
    } else {
      finalData = editData
        .filter((product) => selectedRowKeys.includes(product.productId))
        .map((record) => onFinish(record));
    }
    const result = await updateProduct(finalData);

    setEditData((prev) => {
      let newData = [...prev];
      newData = newData.map((i) => {
        const variantResult = result.find((v) => i.productId === v.product_id);
        if (variantResult) {
          return {
            ...i,
            updateStatus: variantResult.status,
            updateMessage: variantResult.message,
          };
        } else return i;
      });

      return newData;
    });
    setSelectedRowKeys([]);
    setUpdated(true);
  };

  const columns = [
    {
      title: "Name",
      fixed: "left",
      dataIndex: "name",
      key: "name",
      render: (_, record) => (
        <Input
          value={record.name}
          onChange={(e) => {
            handleInputChange(e.target.value, record.key, "name");
          }}
        />
      ),
    },
    {
      title: "Variant Price",
      key: "variant_mrp",
      width: 100,
      filterIcon: <EditOutlined style={{ fontSize: 16, color: "black" }} />,
      filterDropdown: () => (
        <div style={{ padding: 12 }}>
          <p>Price</p>
          <InputNumber
            style={{ marginBottom: 14, width: "100%" }}
            onChange={(e) =>
              handleInputChange(e, null, "variant", null, "mrp", true)
            }
          />
          <p>Change by percent</p>
          <Button
            onClick={() => {
              if (percentValue)
                handleInputChange(
                  10,
                  null,
                  "variant",
                  null,
                  "mrp",
                  true,
                  1 - percentValue / 100
                );
            }}
          >
            -
          </Button>
          <InputNumber
            value={percentValue}
            onChange={(e) => setPercentValue(e)}
            style={{ margin: "0px 4px" }}
          />
          <Button
            onClick={() => {
              if (percentValue)
                handleInputChange(
                  10,
                  null,
                  "variant",
                  null,
                  "mrp",
                  true,
                  1 + percentValue / 100
                );
            }}
          >
            +
          </Button>
        </div>
      ),
    },
    {
      title: "Vendor",
      filterIcon: <EditOutlined style={{ fontSize: 16, color: "black" }} />,
      filterDropdown: () => (
        <div style={{ padding: 12 }}>
          <Select
            style={{ width: "100%" }}
            placeholder="Select Vendor"
            onChange={(e) => {
              handleInputChange(e, false, "vendor_id");
            }}
          >
            {vendorData
              ?.filter((i) => i.status)
              ?.map((item) => (
                <Option key={item.user_id} value={item.user_id}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>{item.company}</span>
                  </div>
                </Option>
              ))}
          </Select>
        </div>
      ),

      dataIndex: "vendor_id",
      key: "vendor",
      render: (vendor, record) => {
        return (
          <Select
            style={{ width: "100%" }}
            placeholder="Select Vendor"
            value={vendor}
            onChange={(e) => {
              handleInputChange(e, record.key, "vendor_id");
            }}
          >
            {vendorData
              ?.filter((i) => i.status)
              ?.map((item) => (
                <Option key={item.user_id} value={item.user_id}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>{item.company}</span>
                  </div>
                </Option>
              ))}
          </Select>
        );
      },
    },
    {
      title: "Variant Discount",
      key: "variant_discount",
      width: 100,
      filterIcon: <EditOutlined style={{ fontSize: 16, color: "black" }} />,
      filterDropdown: () => (
        <div style={{ padding: 12 }}>
          <p>Discount</p>
          <InputNumber
            style={{ marginBottom: 14, width: "100%" }}
            onChange={(e) =>
              handleInputChange(
                e,
                null,
                "variant",
                null,
                "discount_percent",
                true
              )
            }
          />
          <p>Change by percent</p>
          <Button
            onClick={() => {
              if (percentValue)
                handleInputChange(
                  10,
                  null,
                  "variant",
                  null,
                  "discount_percent",
                  true,
                  1 - percentValue / 100
                );
            }}
          >
            -
          </Button>
          <InputNumber
            value={percentValue}
            onChange={(e) => setPercentValue(e)}
            style={{ margin: "0px 4px" }}
          />
          <Button
            onClick={() => {
              if (percentValue)
                handleInputChange(
                  10,
                  null,
                  "variant",
                  null,
                  "discount_percent",
                  true,
                  1 + percentValue / 100
                );
            }}
          >
            +
          </Button>
        </div>
      ),
    },

    {
      title: "Unit",
      filterIcon: <EditOutlined style={{ fontSize: 16, color: "black" }} />,
      filterDropdown: () => (
        <div style={{ padding: 12 }}>
          <Select
            style={{ width: "100%" }}
            placeholder="Item unit"
            onChange={(e) => {
              handleInputChange(e, false, "unit");
            }}
          >
            {unitData
              ?.filter((i) => i.status)
              ?.map((item) => (
                <Option key={item.unit_id} value={item.unit_id}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>{item.name}</span>
                  </div>
                </Option>
              ))}
          </Select>
        </div>
      ),

      dataIndex: "unit",
      key: "unit",
      render: (unit, record) => {
        return (
          <Select
            value={unit}
            style={{ width: "100%" }}
            placeholder="Item unit"
            onChange={(e) => {
              handleInputChange(e, record.key, "unit");
            }}
          >
            {unitData
              ?.filter((i) => i.status)
              ?.map((item) => (
                <Option key={item.unit_id} value={item.unit_id}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>{item.name}</span>
                  </div>
                </Option>
              ))}
          </Select>
        );
      },
    },
    {
      title: "Variant Quantity",
      key: "variant_quantity",
      width: 100,
      filterIcon: <EditOutlined style={{ fontSize: 16, color: "black" }} />,
      filterDropdown: () => (
        <div style={{ padding: 12 }}>
          <p>Quantity</p>
          <InputNumber
            style={{ marginBottom: 14, width: "100%" }}
            onChange={(e) =>
              handleInputChange(e, null, "variant", null, "quantity", true)
            }
          />
          <p>Change by percent</p>
          <Button
            onClick={() => {
              if (percentValue)
                handleInputChange(
                  10,
                  null,
                  "variant",
                  null,
                  "quantity",
                  true,
                  1 - percentValue / 100
                );
            }}
          >
            -
          </Button>
          <InputNumber
            value={percentValue}
            onChange={(e) => setPercentValue(e)}
            style={{ margin: "0px 4px" }}
          />
          <Button
            onClick={() => {
              if (percentValue)
                handleInputChange(
                  10,
                  null,
                  "variant",
                  null,
                  "quantity",
                  true,
                  1 + percentValue / 100
                );
            }}
          >
            +
          </Button>
        </div>
      ),
    },

    {
      title: "Type",
      filterIcon: <EditOutlined style={{ fontSize: 16, color: "black" }} />,
      filterDropdown: () => (
        <div style={{ padding: 12 }}>
          <Select
            style={{ width: "100%" }}
            placeholder="Product type"
            onChange={(e) => {
              handleInputChange(e, false, "product_type");
            }}
          >
            {cmsData
              ?.filter(
                (i) => i.status && i.entity_id === "dim.product.product_type"
              )
              ?.map((item) => (
                <Option key={item.cms_id} value={item.data}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>{item.data}</span>
                  </div>
                </Option>
              ))}
          </Select>
        </div>
      ),

      dataIndex: "product_type",
      key: "product_type",
      render: (type, record) => {
        return (
          <Select
            style={{ width: "100%" }}
            placeholder="Product type"
            value={type}
            onChange={(e) => {
              handleInputChange(e, record.key, "product_type");
            }}
          >
            {cmsData
              ?.filter(
                (i) => i.status && i.entity_id === "dim.product.product_type"
              )
              ?.map((item) => (
                <Option key={item.cms_id} value={item.data}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>{item.data}</span>
                  </div>
                </Option>
              ))}
          </Select>
        );
      },
    },
    {
      title: "Variant Min Quantity",
      key: "variant_min",
      width: 100,
      filterIcon: <EditOutlined style={{ fontSize: 16, color: "black" }} />,
      filterDropdown: () => (
        <div style={{ padding: 12 }}>
          <p>Min Quantity</p>
          <InputNumber
            style={{ marginBottom: 14, width: "100%" }}
            onChange={(e) =>
              handleInputChange(e, null, "variant", null, "q_notify", true)
            }
          />
          <p>Change by percent</p>
          <Button
            onClick={() => {
              if (percentValue)
                handleInputChange(
                  10,
                  null,
                  "variant",
                  null,
                  "q_notify",
                  true,
                  1 - percentValue / 100
                );
            }}
          >
            -
          </Button>
          <InputNumber
            value={percentValue}
            onChange={(e) => setPercentValue(e)}
            style={{ margin: "0px 4px" }}
          />
          <Button
            onClick={() => {
              if (percentValue)
                handleInputChange(
                  10,
                  null,
                  "variant",
                  null,
                  "q_notify",
                  true,
                  1 + percentValue / 100
                );
            }}
          >
            +
          </Button>
        </div>
      ),
    },

    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      filterIcon: <EditOutlined style={{ fontSize: 16, color: "black" }} />,
      filterDropdown: () => (
        <div style={{ padding: 12 }}>
          <Cascader
            options={cascadeCategory}
            changeOnSelect
            showSearch={true}
            onChange={(e) => handleInputChange(e, false, "category")}
          />
        </div>
      ),
      render: (category, record) => {
        return (
          <Cascader
            value={category}
            options={cascadeCategory}
            changeOnSelect
            showSearch={true}
            onChange={(e) => handleInputChange(e, record.key, "category")}
          />
        );
      },
    },

    {
      title: "Item no.",

      dataIndex: "model",
      key: "model",
      render: (item, record) => (
        <Input
          value={item}
          onChange={(e) =>
            handleInputChange(e.target.value, record.key, "model")
          }
        />
      ),
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      render: (item, record) => (
        <Input
          value={item}
          onChange={(e) => handleInputChange(e.target.value, record.key, "sku")}
        />
      ),
    },
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      render: (item, record) => (
        <Input
          value={item}
          onChange={(e) =>
            handleInputChange(e.target.value, record.key, "slug")
          }
        />
      ),
    },
    {
      title: "tags",
      dataIndex: "tags",
      key: "tags",
      filterIcon: <EditOutlined style={{ fontSize: 16, color: "black" }} />,

      render: (item, record) => (
        <Select
          mode="multiple"
          allowClear
          style={{
            width: "100%",
          }}
          value={item?.[0].tags?.split(",").filter((i) => !!i) ?? []}
          placeholder="Please select"
          onChange={(e) =>
            handleInputChange(
              [{ ...item[0], tags: e.join(",") }],
              record.key,
              "tags"
            )
          }
        >
          {cmsData
            ?.filter((i) => i.status && i.entity_id === "dim.product.tags")
            ?.map((item) => (
              <Option key={item.cms_id} value={item.data}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span>{item.data}</span>
                </div>
              </Option>
            ))}
        </Select>
      ),
      filterDropdown: () => (
        <div style={{ padding: 12 }}>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: "100%",
            }}
            value={tagRef.current}
            placeholder="Please select"
            onChange={(e) => {
              handleInputChange(e.join(","), false, "tags", 0, "tags");
              tagRef.current = e;
            }}
          >
            {cmsData
              ?.filter((i) => i.status && i.entity_id === "dim.product.tags")
              ?.map((item) => (
                <Option key={item.cms_id} value={item.data}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span>{item.data}</span>
                  </div>
                </Option>
              ))}
          </Select>
        </div>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filterIcon: <EditOutlined style={{ fontSize: 16, color: "black" }} />,
      filterDropdown: () => (
        <div style={{ padding: 12 }}>
          <Switch onChange={(e) => handleInputChange(e, false, "status")} />
        </div>
      ),
      render: (status, record) => (
        <Space size="middle">
          <Switch
            checked={status}
            onChange={() => handleInputChange(!status, record.key, "status")}
          />
        </Space>
      ),
    },
    {
      title: "Sync",
      key: "sync",
      render: (record) => (
        <Space size="middle">
          <SyncOutlined
            style={{ fontSize: 22, color: "green" }}
            onClick={() => {
              setModalData({ ...record, uniId: record.productId });
              setShowModalSync(true);
            }}
          />
        </Space>
      ),
      width: 50,
    },
    {
      title: "Update Status",
      dataIndex: "updateStatus",
      key: "status",
      width: 100,
      align: "center",
      fixed: "right",
      render: (status, record) => getRowClassName(record),
    },
  ];

  return (
    <div className="site-page-header-ghost-wrapper bulk-edit-page">
      <PageHeader
        title={
          <p>
            <ArrowLeftOutlined onClick={() => history.goBack()} /> Bulk Edit
          </p>
        }
        className="site-page-header"
        extra={[
          <Form.Item label="Table columns" style={{ marginBottom: 0 }}>
            <TreeSelect
              onChange={(e) => {
                save_storage("product-columns", e);
                setIncludedTableColumns(e);
              }}
              value={includedTableColumns}
              treeCheckable={true}
              showCheckedStrategy="SHOW_PARENT"
              placeholder="Please select"
              style={{ width: 250 }}
              treeData={[
                { title: "Name", value: "name", key: "name" },
                { title: "Vendor", value: "vendor", key: "vendor" },
                { title: "Unit", value: "unit", key: "unit" },
                {
                  title: "Product type",
                  value: "product_type",
                  key: "product_type",
                },
                { title: "Category", value: "category", key: "category" },
                { title: "Model", value: "model", key: "model" },
                { title: "Sku", value: "sku", key: "sku" },
                { title: "Slug", value: "slug", key: "slug" },
                { title: "Tags", value: "tags", key: "tags" },
                { title: "Status", value: "status", key: "status" },
                { title: "Sync", value: "sync", key: "sync" },
              ]}
              maxTagCount="responsive"
            />
          </Form.Item>,
          <Form.Item label="variant columns" style={{ marginBottom: 0 }}>
            <TreeSelect
              onChange={(e) => {
                save_storage("variant-columns", e);
                setIncludedTableVariantColumns(e);
              }}
              value={includedTableVariantColumns}
              treeCheckable={true}
              showCheckedStrategy="SHOW_PARENT"
              placeholder="Please select"
              style={{ width: 250 }}
              treeData={[
                {
                  title: "Price",
                  value: "variant_mrp",
                  key: "variant_mrp",
                },
                {
                  title: "Discount",
                  value: "variant_discount",
                  key: "variant_discount",
                },
                {
                  title: "Quantity",
                  value: "variant_quantity",
                  key: "variant_quantity",
                },
                {
                  title: "Min Quantity",
                  value: "variant_min",
                  key: "variant_min",
                },
                {
                  title: "Variant sku",
                  value: "variant_sku",
                  key: "variant_sku",
                },
                { title: "Var type 1", value: "var_type_1", key: "var_type_1" },
                { title: "Title 1", value: "title_1", key: "title_1" },
                { title: "Var type 2", value: "var_type_2", key: "var_type_2" },
                { title: "Title 2", value: "title_2", key: "title_2" },
                { title: "Var type 3", value: "var_type_3", key: "var_type_3" },
                { title: "Title 3", value: "title_3", key: "title_3" },
              ]}
              maxTagCount="responsive"
            />
          </Form.Item>,
          <Button key="2" onClick={() => toggleFilter(true)}>
            Filters
          </Button>,
          <Button key="1" type="primary" onClick={() => onSubmit()}>
            Save
          </Button>,
        ]}
      ></PageHeader>
      <CustomPaginatedTable
        columns={columns?.filter(
          (i) =>
            includedTableColumns.includes(i.key) ||
            includedTableVariantColumns.includes(i.key)
        )}
        dataSource={editData}
        setPage={setPage}
        page={page}
        limit={50}
        initReloadRequired={false}
        REFRESH={PRODUCT_REFRESH}
        filterData={filters}
        showLimitChanger
        getData={getData}
        loadMoreButton
        tableProps={{
          expandedRowRender,
          defaultExpandAllRows: true,
          rowSelection: {
            selectedRowKeys,
            onChange: onSelectChange,
          },
          tableLayout: "auto",
          scroll: {
            x: 1500,
          },
        }}
      />

      <ModalFilterProduct
        formData={filters}
        visible={showFilter}
        handleClose={toggleFilterDialog}
      />
      <ModalSync
        data={modalData}
        showModal={showModalSync}
        toggleModal={setShowModalSync}
        handleProductSync={handleProductSync}
      />
    </div>
  );
}
const mapStateToProps = (state) => ({
  data: state.products.list,
  cmsData: state.cms?.cms,
  etsySyncIds: state.sync.etsyIds,
  etsyGoldSyncIds: state.sync.etsyGoldIds,
  shopifySyncIds: state.sync.shopifyIds,
  pagination: state.products.pagination,
  filter: state.products.filter,
  PRODUCT_REFRESH: state.levent.PRODUCT_REFRESH,

  products: state.products,
  category: state.category.data,
  cascadeCategory: state.category.cascade,
  isEdit: state.products.isEdit,
  productData: state.products.product,
  unitData: state.unit.data,
  vendorData: state.vendor.data,
});

const mapDispatchToProps = (dispatch) => ({
  editProduct: (data) =>
    dispatch({
      type: "PRODUCT_EDIT",
      data: data,
    }),
  resetProducts: () =>
    dispatch({
      type: "PRODUCT_LIST_CLEAR",
    }),

  syncOnEtsy: (productId) => dispatch(syncEtsy(productId)),
  syncOnEtsyGold: (productId) => dispatch(syncEtsyGold(productId)),

  syncOnShopify: (productId) => dispatch(syncShopify(productId)),
  syncOnShopifyTJ: (productId) => dispatch(syncShopifyTJ(productId)),
  updatePagination: (data) => {
    dispatch({
      type: "PRODUCT_PAGE_CHANGE",
      data,
    });
  },
  getData: (data, loadMore) => dispatch(fetchData(data, "", loadMore)),
  updateData: (productId, data) => dispatch(putData(productId, data)),
  refreshProduct: () =>
    dispatch({ type: "FIRE_EVENT", data: { event: "PRODUCT_REFRESH" } }),
  getCategories: () => dispatch(fetchCategory()),
  getVendors: () => dispatch(fetchVendor()),
  addUnit: (data) => dispatch(createOrUpdateUnit(data)),
  createProduct: (data) => dispatch(createData(data)),
  updateProduct: (data) => dispatch(putBulkData(data)),
  getCms: (data) => dispatch(fetchCms(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageProduct);
