
const defaultUser =  {
  "gender": 0,
  "tax": null,
  "address": "",
  "name": "",
  "email": "",
  "discount": 0,
  "company": null,
  "mobile": "",
  "is_deleted": 0
};


const emailgroup = (state = {list:[], isEdit : false, item:defaultUser}, action) => {
  switch (action.type) {

    case 'EMAIL_GROUP_LIST':

      return {...state, list: action.data};

      case 'EMAIL_GROUP_VIEW':

        state.item = action.data;
        state.isEdit = true;
        return state;
    default:
      return state
  }
}
export default emailgroup
