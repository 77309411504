import { combineReducers } from "redux";
import products from "./product";
import common from "./common";
import category from "./category";
import order from "./order";
import users from "./user";
import emailSubscriber from "./emailSubscriber";
import notification from "./notification";
import sync from "./sync";
import unit from "./unit";
import vendor from "./vendor";
import type from "./type";
import auth from "./auth";
import levent from "./levent";
import emailgroup from "./emailgroup";
import banner from "./banner";
import cms from "./cms";

export default combineReducers({
  products,
  common,
  category,
  order,
  users,
  emailSubscriber,
  notification,
  sync,
  unit,
  vendor,
  type,
  auth,
  levent,
  emailgroup,
  banner,
  cms,
});
