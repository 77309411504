import { Button, Select, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const PageLimitSelect = ({ handleChange, value }) => (
  <Select
    value={value}
    onChange={handleChange}
    options={[
      {
        value: 10,
        label: "10 / Page",
      },
      {
        value: 20,
        label: "20 / Page",
      },
      {
        value: 50,
        label: "50 / Page",
      },
    ]}
  />
);

function CustomPaginatedTable({
  columns,
  dataSource,
  REFRESH,
  getData,
  query,
  page,
  setPage,
  limit = 50,
  filterData,
  form,
  loadMoreButton,
  showLimitChanger = false,
  showIndexColumn = false,
  initReloadRequired = true,
  tableProps = {},
}) {
  const [loading, setLoading] = useState(false);
  const [init, setInit] = useState(true);

  const [pageLimit, setPageLimit] = useState(limit);
  const loadMore = useRef(false);

  const loadData = async () => {
    setLoading(true);
    let objData = { page, limit: pageLimit };
    if (query) {
      objData = { ...objData, query };
    }
    if (filterData) {
      objData = { ...objData, ...filterData };
    }
    await getData(objData, loadMore.current);
    loadMore.current = false;
    setLoading(false);
  };

  useEffect(() => {
    if (form) form.resetFields();

    const debounceTimer = setTimeout(() => {
      if (init) {
        if (initReloadRequired) loadData();
        setInit(false);
      } else loadData();
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [page, pageLimit, query, REFRESH, filterData]);

  return (
    <>
      <Table
        {...tableProps}
        columns={
          showIndexColumn
            ? [
                {
                  title: "#",
                  key: "#",
                  render: (item, record, index) => page * pageLimit + index + 1,
                },
                ...columns,
              ]
            : columns
        }
        dataSource={dataSource}
        size="small"
        loading={loading}
        pagination={false}
      />
      <div
        style={{
          textAlign: loadMoreButton ? "center" : "right",
          marginTop: 24,
        }}
      >
        {loadMoreButton && (
          <>
            <Button
              style={{
                marginBottom: 24,
                marginRight: showLimitChanger ? 8 : 0,
              }}
              onClick={() => {
                loadMore.current = true;
                setPage((prev) => prev + 1);
              }}
              disabled={dataSource?.length % pageLimit != 0}
            >
              Load More
            </Button>
            {showLimitChanger && (
              <PageLimitSelect
                value={pageLimit}
                handleChange={(e) => {
                  setPage(0);
                  setPageLimit(e);
                }}
              />
            )}
          </>
        )}
        {!loadMoreButton && (
          <>
            <Button
              icon={<LeftOutlined />}
              type="text"
              size="small"
              style={{ marginRight: 12 }}
              onClick={() => setPage((prev) => prev - 1)}
              disabled={page === 0}
            />
            <Button
              icon={<RightOutlined />}
              type="text"
              size="small"
              onClick={() => {
                setPage((prev) => prev + 1);
              }}
              disabled={dataSource?.length < pageLimit}
            />
            {showLimitChanger && (
              <PageLimitSelect
                value={pageLimit}
                handleChange={(e) => {
                  setPage(0);
                  setPageLimit(e);
                }}
              />
            )}
          </>
        )}
      </div>
    </>
  );
}

export default CustomPaginatedTable;
