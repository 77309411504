import {
  requestStart,
  requestFinish,
  getApi,
  postApi,
  updateApi,
} from "./common";
import {
  API_BASE_URL,
  PATH_PRODUCTS as PATH_CONTEXT,
  PATH_API_COMMON,
  PATH_MANAGE,
  PATH_API_MANAGE,
  PATH_PRODUCTS_EXPORT,
} from "../config";

const ACTION_META_NAME = "PRODUCT";

export function fetchData(reqData, type = "", loadMore = false) {
  return async function (dispatch) {
    let QUERY_PARAMS = "";

    if (reqData) {
      if (reqData.query) {
        QUERY_PARAMS = `query=${reqData.query}`;
      }

      if (reqData.category) {
        if (reqData.category.length > 0) {
          QUERY_PARAMS = `${QUERY_PARAMS}&searchIn=${reqData.category[0]}`;
        }

        if (reqData.category.length > 1) {
          QUERY_PARAMS = `${QUERY_PARAMS}&searchIn=${reqData.category[1]}`;
        }

        if (reqData.category.length > 2) {
          QUERY_PARAMS = `${QUERY_PARAMS}&searchIn=${reqData.category[2]}`;
        }
      }
      if (reqData.sku) {
        QUERY_PARAMS = `${QUERY_PARAMS}&sku=${reqData.sku}`;
      }

      if (reqData.tags) {
        QUERY_PARAMS = `${QUERY_PARAMS}&tags=${reqData.tags}`;
      }
      if (reqData.model) {
        QUERY_PARAMS = `${QUERY_PARAMS}&model=${reqData.model}`;
      }

      if (reqData.only_deactive) {
        QUERY_PARAMS = `${QUERY_PARAMS}&only_deactive=true`;
      }
      if (reqData.page) {
        QUERY_PARAMS = `${QUERY_PARAMS}&page=${reqData.page}`;
      } else {
        QUERY_PARAMS = `${QUERY_PARAMS}&page=0`;
      }

      if (reqData.limit) {
        QUERY_PARAMS = `${QUERY_PARAMS}&limit=${reqData.limit}`;
      } else {
        QUERY_PARAMS = `${QUERY_PARAMS}&limit=200`;
      }
    }

    const data = await getApi(dispatch, {
      url: `${API_BASE_URL}${PATH_API_MANAGE}products${
        type ? `/${type}` : ""
      }?${QUERY_PARAMS}`,
    });
    console.log("api called", data);

    if (data != null) {
      if (loadMore) {
        dispatch({
          type: ACTION_META_NAME + "_LIST_ADD",
          data: data,
          receivedAt: Date.now(),
        });
      } else {
        dispatch({
          type: ACTION_META_NAME + "_LIST",
          data: data,
          receivedAt: Date.now(),
        });
      }
    }

    dispatch({
      type: "NOTIFICATION_HIDE",
      data: {
        title: "",
        type: "success",
        body: "",
      },
    });
  };
}

export function fetchDataById(type = "") {
  return async function (dispatch) {
    const data = await getApi(dispatch, {
      url: `${API_BASE_URL}${PATH_API_MANAGE}product/${type}`,
    });
    console.log("api called", data);

    if (data != null) {
      return data;
    }
  };
}

export function createData(reqData, type = "") {
  return async function (dispatch) {
    const data = await postApi(dispatch, {
      url: `${API_BASE_URL}${PATH_MANAGE}${PATH_CONTEXT}`,
      body: reqData,
    });

    if (data != null) {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: ACTION_META_NAME + " Created",
          type: "success",
          body: "",
        },
      });

      dispatch({
        type: ACTION_META_NAME + "_CREATED",
        data: data,
        receivedAt: Date.now(),
      });

      setTimeout(
        () =>
          dispatch({
            type: "FIRE_EVENT",
            data: {
              event: ACTION_META_NAME + "_REFRESH",
            },
          }),
        200
      );
      return data;
    }
  };
}

export function putData(productId, reqData) {
  return async function (dispatch) {
    const data = await updateApi(dispatch, {
      url: `${API_BASE_URL}${PATH_MANAGE}${PATH_CONTEXT}/${productId}`,
      body: reqData,
    });

    if (data != null) {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: "Product Updated",
          type: "success",
          body: "",
        },
      });

      dispatch({
        type: ACTION_META_NAME + "_UPDATED",
        data: data,
        receivedAt: Date.now(),
      });

      dispatch({
        type: "FIRE_EVENT",
        data: {
          event: ACTION_META_NAME + "_REFRESH",
        },
      });
    }
  };
}

export function putBulkData(reqData) {
  return async function (dispatch) {
    const data = await updateApi(dispatch, {
      url: `${API_BASE_URL}${PATH_MANAGE}${PATH_CONTEXT}/bulk/update`,
      body: reqData,
    });

    if (data != null) {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: "Product Updated",
          type: "success",
          body: "",
        },
      });

      dispatch({
        type: ACTION_META_NAME + "_UPDATED",
        data: data,
        receivedAt: Date.now(),
      });

      dispatch({
        type: "FIRE_EVENT",
        data: {
          event: ACTION_META_NAME + "_REFRESH",
        },
      });
      return data;
    }
  };
}

export function productBypass(reqData, type = "") {
  return async function (dispatch) {
    const data = await postApi(dispatch, {
      url: `${API_BASE_URL}api-public/bypass/${reqData.id}`,
      body: reqData,
    });

    if (data != null) {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: ACTION_META_NAME + " Created",
          type: "success",
          body: "",
        },
      });

      dispatch({
        type: ACTION_META_NAME + "_CREATED",
        data: data,
        receivedAt: Date.now(),
      });

      dispatch({
        type: "FIRE_EVENT",
        data: {
          event: ACTION_META_NAME + "_REFRESH",
        },
      });
    }
  };
}

export function getExportFile(reqData) {
  return async function (dispatch) {
    const response = await fetch(
      `${API_BASE_URL}${PATH_API_MANAGE}${PATH_PRODUCTS_EXPORT}`,
      {
        method: "POST",
        body: JSON.stringify(reqData),
      }
    );

    if (response.ok) {
      const data = await response.blob();
      const fileUrl = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = "export"; // Set the filename here
      link.style.display = "none";

      // Add the link to the DOM and simulate a click event
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: "Products Exported",
          type: "success",
          body: "",
        },
      });
    } else {
      dispatch({
        type: "NOTIFICATION_SHOW",
        data: {
          title: "Something went wrong",
          type: "error",
          body: "",
        },
      });
    }
  };
}
