const type = (
  state = {
    data: [],
    selected: {},

    editData: {
      name: "",
      id: false,
    },
  },
  action
) => {
  switch (action.type) {
    case "TYPE_LIST":
      return {
        ...state,
        data: action.data,
      };

    default:
      return state;
  }
};
export default type;
