import React, { useState, useEffect, useRef } from "react";

import { connect } from "react-redux";

import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  Divider,
  List,
  Radio,
  Checkbox,
  Spin,
  AutoComplete,
} from "antd";

import {
  CloseOutlined,
  DollarOutlined,
  MinusOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import {
  PlusOutlined,
  MinusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { Table, Tag, Space, Popconfirm, message, Image, Switch } from "antd";

import SectionUpload from "../component/SectionUpload";
import { SectionTags } from "../component/SectionTags";
import ModalSync from "../component/modal/ModalSync";

import { Cascader } from "antd";
import { PageHeader, Descriptions } from "antd";
import { useHistory } from "react-router-dom";

import update from "immutability-helper";

import styles from "./PageAddOrder.module.css";

import {
  syncEtsy,
  syncShopify,
  syncEtsyGold,
  syncShopifyTJ,
} from "../actions/sync";
import {
  fetchData as fetchCategory,
  createOrUpdate,
  createOrUpdateUnit,
  deleteUnit,
  updateVendor,
} from "../actions/category";

import {
  fetchData,
  putData,
  createData,
  fetchDataById,
} from "../actions/product";
import DragListView from "react-drag-listview";
import SingleUpload from "../component/SingleUpload";
import { useParams } from "react-router-dom";
import ModalAddVendor from "../component/modal/ModalAddVendor";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;

function PageAddOrder({
  getData,
  productData,
  addUnit,
  getProductSearch,

  updateVendor,

  updateProduct,

  createProduct,
}) {
  const [loading, setLoading] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  const [productSearchVisible, setProductSearchVisible] = React.useState(false);

  const history = useHistory();

  const [form] = Form.useForm();

  const { productId } = useParams();

  //   useEffect(() => {
  //     if (productId === "add") {
  //       form.resetFields();
  //       setFileList([]);
  //     } else {
  //       fetchData();
  //     }
  //   }, [PRODUCT_REFRESH]);

  const [autoCompleteValue, setAutoCompleteValue] = useState();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let timer;
    if (!!autoCompleteValue)
      timer = setTimeout(
        () => getProductSearch({ query: autoCompleteValue, limit: 5 }),
        300
      );
    return () => clearTimeout(timer);
  }, [autoCompleteValue]);

  const getProductOptions = (data) => {
    if (!data || data.length === 0) return [];

    return data.map((product) => {
      if (!product?.variant || product?.variant?.length === 0) return;
      else {
        return {
          label: renderTitle(product.name),
          options: product?.variant?.map((variant) => renderItem(variant)),
        };
      }
    });
  };

  const columns = [
    {
      title: "Product",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Variant",
      dataIndex: "variant_name",
      key: "variant_name",
    },
    {
      title: "Unit Price",
      dataIndex: "unit_price",
      key: "unit_price",
    },
    {
      title: "Quantity",
      key: "Quantity",
      dataIndex: "quantity",
      render: (item, record, index) => (
        <p style={{ marginBottom: 0 }}>{item}</p>
      ),
    },
    {
      title: "Total",
      key: "total",
      render: (_, record) => record.unit_price * record.quantity,
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "uniId",
      render: (uniId) => (
        <p
          style={{ color: "red" }}
          onClick={() =>
            setTableData((prev) => prev.filter((item) => item.uniId !== uniId))
          }
        >
          Delete
        </p>
      ),
    },
  ];

  const onFinish = async (values) => {};

  //   const [showVendorModal, setShowVendorModal] = useState({ show: false });

  //   const handleVendorSubmit = async (values, isEdit) => {
  //     if (values) {
  //       if (isEdit) await updateVendor(values.uniId, values);
  //       else await addUnit(values, true);
  //     }
  //     setShowVendorModal({ show: false });
  //   };

  const renderTitle = (title) => <span>{title}</span>;
  const renderItem = (variant) => ({
    value: `${variant.product_id}-${variant.product_to_variants_id}`,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {variant.title_1}
        <span>
          <DollarOutlined /> {variant.mrp}
        </span>
      </div>
    ),
  });

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <div className="site-page-header-ghost-wrapper">
      <PageHeader
        title="New Order"
        className="site-page-header"
        extra={[
          <Button
            key="3"
            onClick={() => {
              form.submit();
            }}
          >
            Create
          </Button>,
        ]}
      ></PageHeader>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        style={{ marginBottom: 16 }}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="isFormEdit" hidden={true}></Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="uniId" hidden={true}></Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="baseId" hidden={true}></Form.Item>
          </Col>

          <Col
            span={24}
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: 16,
            }}
          >
            <div className={styles.add_section}>
              <div className={styles.header}>
                <p className={styles.add_section_heading}>Order No #1</p>
                <p
                  className={styles.add_section_heading}
                  style={{ color: "#848482" }}
                >
                  {moment().format("ddd, D MMMM, YYYY")}
                </p>
              </div>

              <Table
                style={{ marginBottom: 16 }}
                columns={columns}
                dataSource={tableData}
                title={() => (
                  <div className={styles.header}>
                    <p className={styles.add_section_heading}>Products</p>
                    {productSearchVisible ? (
                      <AutoComplete
                        value={autoCompleteValue}
                        dropdownMatchSelectWidth={500}
                        style={{ width: 250 }}
                        onSelect={(e) => {
                          const [productId, variantId] = e.split("-");

                          if (productId && variantId && productData) {
                            const product = productData?.find(
                              (i) => i.product_id == productId
                            );
                            if (product) {
                              const variantData = product.variant.find(
                                (i) => i.product_to_variants_id == variantId
                              );
                              if (variantData) {
                                setTableData((prev) => {
                                  const index = prev.findIndex(
                                    (obj) => obj.uniId == e
                                  );

                                  if (index == -1) {
                                    return [
                                      ...prev,
                                      {
                                        uniId: e,
                                        name: product.name,
                                        variant_name: variantData.title_1,
                                        product_id: productId,
                                        variant_id: variantId,
                                        quantity: 1,
                                        unit_price: variantData.mrp,
                                      },
                                    ];
                                  } else {
                                    prev[index].quantity += 1;
                                    return prev;
                                  }
                                });
                              }
                            }
                          }
                          setProductSearchVisible(false);
                        }}
                        onSearch={(e) => {
                          setAutoCompleteValue(e);
                        }}
                        options={getProductOptions(productData)}
                      />
                    ) : (
                      <Button
                        shape="circle"
                        type="text"
                        onClick={() => setProductSearchVisible(true)}
                        className={styles.circular_button}
                        icon={<PlusOutlined />}
                        size="large"
                      />
                    )}
                  </div>
                )}
                bordered
              />
            </div>
          </Col>
        </Row>
      </Form>
      {/* <ModalAddVendor
        visible={showVendorModal?.show}
        formData={showVendorModal?.formData}
        handleClose={handleVendorSubmit}
      /> */}
    </div>
  );
}
const mapStateToProps = (state) => ({
  productData: state.products.list,
  editData: state.products.product,
  etsySyncIds: state.sync.etsyIds,
  etsyGoldSyncIds: state.sync.etsyGoldIds,
  shopifySyncIds: state.sync.shopifyIds,
  pagination: state.products.pagination,
  filter: state.products.filter,
  PRODUCT_REFRESH: state.levent.PRODUCT_REFRESH,

  products: state.products,
  category: state.category.data,
  cascadeCategory: state.category.cascade,
  isEdit: state.products.isEdit,
  unitData: state.unit.data,
  vendorData: state.vendor.data,
});

const mapDispatchToProps = (dispatch) => ({
  editProduct: (data) =>
    dispatch({
      type: "PRODUCT_EDIT",
      data: data,
    }),
  syncOnEtsy: (productId) => dispatch(syncEtsy(productId)),
  syncOnEtsyGold: (productId) => dispatch(syncEtsyGold(productId)),
  syncOnShopify: (productId) => dispatch(syncShopify(productId)),
  syncOnShopifyTJ: (productId) => dispatch(syncShopifyTJ(productId)),
  getProductSearch: (data) => dispatch(fetchData(data)),
  addUnit: (data, vendor) => dispatch(createOrUpdateUnit(data, vendor)),
  removeUnit: (data, vendor) => dispatch(deleteUnit(data, vendor)),
  updateVendor: (id, data) => dispatch(updateVendor(id, data)),
  createProduct: (data) => dispatch(createData(data)),
  updateProduct: (productId, data) => dispatch(putData(productId, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageAddOrder);
