
import { REQUEST_START, REQUEST_FINISH, AUTH_COMPLETE } from '../actions/common';
import ls from 'local-storage'

const sync = (state = { etsyIds: [], shopifyIds: [], etsyGoldIds: [], shopifyTJIds: [],etsySyncId: 0, shopifySyncId: 0, etsyGoldSyncId: 0, shopifyTJSyncId: 0 }, action) => {
  let ids = state.etsyIds;


  switch (action.type) {


    case 'SYNC_PRODUCT_START':



      if (ids.filter(id => id == action.data.productId).length == 0) {
        ids.push(action.data.productId);
      }

      return {
        ...state,
        etsySyncId: action.data.productId,
        etsyIds: ids
      }
    case 'SYNC_PRODUCT_END':




      ids = state.etsyIds.filter(id => id != action.data.productId);

      const updateSyncProductId = action.data.productId === state.etsySyncId ? 0 : state.etsySyncId;
      return {
        ...state,
        etsySyncId: updateSyncProductId,
        etsyIds: ids
      }



    case 'SYNC_ETSY_GOLD_PRODUCT_START':


      const etsyGoldIds = state.etsyGoldIds;

      if (etsyGoldIds.filter(id => id == action.data.productId).length == 0) {
        etsyGoldIds.push(action.data.productId);
      }

      return {
        ...state,
        etsyGoldSyncId: action.data.productId,
        etsyGoldIds: etsyGoldIds
      }

    case 'SYNC_ETSY_GOLD_PRODUCT_END':




      ids = state.etsyGoldIds.filter(id => id != action.data.productId);

      const updateEtsyGoldSyncProductId = action.data.productId === state.etsyGoldSyncId ? 0 : state.etsyGoldSyncId;

      return {
        ...state,
        etsyGoldSyncId: updateEtsyGoldSyncProductId,
        etsyGoldIds: ids
      }

    case 'SYNC_SHOPIFY_PRODUCT_START':


      const shopifyIds = state.shopifyIds;

      if (shopifyIds.filter(id => id == action.data.productId).length == 0) {
        shopifyIds.push(action.data.productId);
      }

      return {
        ...state,
        shopifySyncId: action.data.productId,
        shopifyIds: shopifyIds
      }
    case 'SYNC_SHOPIFY_PRODUCT_END':




      ids = state.shopifyIds.filter(id => id != action.data.productId);

      const updateShopifySyncProductId = action.data.productId === state.shopifySyncId ? 0 : state.shopifySyncId;

      return {
        ...state,
        shopifySyncId: updateShopifySyncProductId,
        shopifyIds: ids
      }

      case 'SYNC_SHOPIFY_TJ_PRODUCT_START':


      const shopifyTJIds = state.shopifyTJIds;

      if (shopifyTJIds.filter(id => id == action.data.productId).length == 0) {
        shopifyTJIds.push(action.data.productId);
      }

      return {
        ...state,
        shopifyTJSyncId: action.data.productId,
        shopifyTJIds: shopifyTJIds
      }
    case 'SYNC_SHOPIFY_TJ_PRODUCT_END':




      ids = state.shopifyTJIds.filter(id => id != action.data.productId);

      const updateShopifyTJSyncProductId = action.data.productId === state.shopifyTJSyncId ? 0 : state.shopifyTJSyncId;

      return {
        ...state,
        shopifyTJSyncId: updateShopifyTJSyncProductId,
        shopifyTJIds: ids
      }


    default:
      return state
  }
}
export default sync