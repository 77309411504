import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import { Drawer, Form, Button, Col, Row, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { Table, Popconfirm } from "antd";

import { PageHeader } from "antd";

import {
  fetchData as fetchCategory,
  createOrUpdateUnit,
  deleteUnit,
} from "../actions/category";

function PageUnit({ data, getCMS, addCMS, removeCMS, REFRESH }) {
  const [showDrawer, toggleDrawer] = React.useState(false);
  const [filterData, setFilterData] = useState({ query: "" });

  const updateDrawer = (showDrawer = false) => {
    toggleDrawer(showDrawer);
  };

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    getCMS({});
    updateDrawer(false);
  }, [REFRESH]);

  const onFormSubmit = (values) => {
    console.log(values);
    if (!values || !values.unit.trim()) return;

    addCMS({ name: values.unit });
    updateDrawer();
  };

  const columns = [
    {
      title: "#",
      key: "#",
      render: (item, record, index) => index + 1,
    },

    {
      title: "Unit",
      dataIndex: "name",
    },
    {
      title: "Action",
      key: "action",
      render: (item) => (
        <Popconfirm
          title="Are you sure to delete this?"
          onConfirm={() => removeCMS(item.unit_id)}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined style={{ fontSize: 22, color: "red" }} />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div className="site-page-header-ghost-wrapper">
      <PageHeader
        title="Units"
        className="site-page-header"
        extra={[
          <Input.Search
            allowClear
            style={{ width: "400px" }}
            defaultValue=""
            placeholder="Search anything.."
            onChange={(e) => {
              setFilterData({
                ...filterData,
                query: e.target.value.toLowerCase(),
              });
            }}
          />,

          <Button
            key="1"
            type="primary"
            onClick={() => {
              form.resetFields();
              updateDrawer(true);
            }}
          >
            Add
          </Button>,
        ]}
      ></PageHeader>
      <Table
        columns={columns}
        dataSource={data.filter((i) =>
          i?.name?.toLowerCase().includes(filterData?.query)
        )}
        size="small"
      />
      <Drawer
        title="Create Product Type"
        width={600}
        onClose={() => updateDrawer(false)}
        visible={showDrawer}
        maskClosable={false}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => updateDrawer(false)}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                form.submit();
              }}
              type="primary"
            >
              Submit
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFormSubmit}
          requiredMark={false}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="unit"
                label="Unit"
                rules={[{ required: true, message: "Please enter unit" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}
const mapStateToProps = (state) => ({
  REFRESH: state.levent.CMS_REFRESH,
  data: state.unit.data?.filter((i) => i.status),
});

const mapDispatchToProps = (dispatch) => ({
  getCMS: () => dispatch(fetchCategory()),
  addCMS: (data) => dispatch(createOrUpdateUnit(data)),
  removeCMS: (data) => dispatch(deleteUnit(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageUnit);
