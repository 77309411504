import React, { useState, useEffect } from "react";

import { Image, Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import ModalPickImage from "./modal/ModalPickImage";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 10;
  if (!isLt2M) {
    message.error("Image must smaller than 10MB!");
  }
  return isJpgOrPng && isLt2M;
}

export default function SingleUpload({
  value,
  onChange,
  showImagePickerModel,
  fileList,
  setFileList = () => {},
  hideText,
}) {
  const [loading, toggleLoading] = useState(false);
  console.log(value, "image value");

  const [showImageModal, setShowImageModal] = useState(false);

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      toggleLoading(!loading);

      return;
    }
    if (info.file.status === "done") {
      console.log(info);
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        toggleLoading(false);
        setShowImageModal(false);
        // setImageUrl(imageUrl);
        // if (!varientUpload)
        onChange(info.fileList[0].response.url);
        setFileList((prev) => [...prev, info.fileList[0]]);
      });
    }
  };

  const handleImageSelect = (selectedImage) => {
    if (selectedImage) onChange(selectedImage?.url);
    setShowImageModal(false);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      {!hideText && <div style={{ marginTop: 8 }}>Upload</div>}
    </div>
  );
  return (
    <>
      {showImagePickerModel ? (
        <div
          className={hideText ? "small-upload" : ""}
          onClick={() => setShowImageModal(true)}
          style={{
            height: hideText ? 32 : 100,
            width: hideText ? 40 : 100,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#fafafa",
            border: "1px dashed #d9d9d9",
            borderRadius: 6,
            cursor: "pointer",
          }}
        >
          {value ? (
            <img src={value} alt="avatar" style={{ width: "100%" }} />
          ) : (
            <>
              <PlusOutlined />
              {!hideText && <div style={{ marginTop: 8 }}>Upload</div>}
            </>
          )}
        </div>
      ) : (
        <div className={hideText ? "small-upload" : ""}>
          <Upload
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            maxCount={1}
            action={`${process.env.REACT_APP_API_MEDIA_URL}`}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            style={{ height: 100, width: 100 }}
          >
            {value ? (
              <img src={value} alt="avatar" style={{ width: "100%" }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
      )}
      <ModalPickImage
        visible={showImageModal}
        imageList={fileList}
        handleClose={handleImageSelect}
        beforeImageUpload={beforeUpload}
        handleImageChange={handleChange}
        loading={loading}
      />
    </>
  );
}
