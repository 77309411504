import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import { Drawer, Form, Button, Col, Row, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { Table, Popconfirm } from "antd";

import { PageHeader } from "antd";

import {
  fetchCms,
  addCMS,
  deleteCMS,
  fetchEntityCms,
} from "../actions/category";
import CustomPaginatedTable from "../component/CustomPaginatedTable";

function PageProductType({ data, getCMS, addCMS, removeCMS, REFRESH }) {
  const [showDrawer, toggleDrawer] = React.useState(false);
  const [filterData, setFilterData] = useState({ query: "" });
  const [page, setPage] = useState(0);

  const updateDrawer = (showDrawer = false) => {
    toggleDrawer(showDrawer);
  };

  const [form] = Form.useForm();

  useEffect(() => {
    updateDrawer(false);
  }, [REFRESH]);

  const onFormSubmit = (values) => {
    console.log(values);
    if (!values || !values.type.trim()) return;
    addCMS(values.type, "dim.product.product_type");
    updateDrawer();
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "data",
    },
    {
      title: "Action",
      key: "action",
      render: (item) => (
        <Popconfirm
          title="Are you sure to delete this?"
          onConfirm={() => removeCMS(item.cms_id)}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined style={{ fontSize: 22, color: "red" }} />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div className="site-page-header-ghost-wrapper">
      <PageHeader
        title="Product Type"
        className="site-page-header"
        extra={[
          <Input.Search
            allowClear
            style={{ width: "400px" }}
            defaultValue=""
            placeholder="Search anything.."
            onChange={(e) => {
              setFilterData({
                ...filterData,
                query: e.target.value.toLowerCase(),
              });
            }}
          />,

          <Button
            key="1"
            type="primary"
            onClick={() => {
              form.resetFields();
              updateDrawer(true);
            }}
          >
            Add
          </Button>,
        ]}
      ></PageHeader>
      <CustomPaginatedTable
        form={form}
        columns={columns}
        filterData={filterData}
        getData={getCMS}
        setPage={setPage}
        page={page}
        REFRESH={REFRESH}
        showLimitChanger
        dataSource={data}
        showIndexColumn
      />
      <Drawer
        title="Create Product Type"
        width={600}
        onClose={() => updateDrawer(false)}
        visible={showDrawer}
        maskClosable={false}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button
              onClick={() => updateDrawer(false)}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                form.submit();
              }}
              type="primary"
            >
              Submit
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFormSubmit}
          requiredMark={false}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="type"
                label="Type"
                rules={[{ required: true, message: "Please enter type" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
}
const mapStateToProps = (state) => ({
  REFRESH: state.levent.CMS_REFRESH,
  data: state.cms?.cms,
});

const mapDispatchToProps = (dispatch) => ({
  getCMS: (data) =>
    dispatch(fetchCms({ ...data, entity_id: "dim.product.product_type" })),
  addCMS: (data, entity) => dispatch(addCMS(data, entity)),
  removeCMS: (data) => dispatch(deleteCMS(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageProductType);
