import { Form, Input, Button, Checkbox } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import { Card } from "antd";
import { notification } from "antd";

import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import { auth } from "../actions/auth";
import { useHistory } from "react-router-dom";

const { Meta } = Card;

const Login = ({ isAuth, authenticate, notificationData }) => {
  const onFinish = (values) => {
    console.log("Received values of form: ", values);

    authenticate(values);
  };

  useEffect(() => {
    if (notificationData.show) openNotificationWithIcon(notificationData);
  }, notificationData.showNotification);

  const openNotificationWithIcon = (data) => {
    const close = () => {
      console.log(
        "Notification was closed. Either the close button was clicked or duration time elapsed."
      );
    };

    notification[data.type]({
      message: data?.title,
      description: data.body,
      placement: "leftBottom",
      style: {
        marginTop: 60,
      },
      onclose: close,
    });
  };

  const history = useHistory();

  useEffect(() => {
    if (isAuth) {
      history.push("/admin/dashboard");
    }
  }, [isAuth]);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundImage: 'url("/background.svg")',
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "40%",
        }}
      >
        <Card
          hoverable
          style={{
            padding: 30,
            width: "100%",
            boxShadow:
              "0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09)",
          }}
          // cover={
          //   <img
          //     alt="example"
          //     src="https://img.freepik.com/free-vector/random-square-halftone-pattern_1409-1062.jpg?size=626&ext=jpg"
          //   />
          // }
        >
          <h1 style={{ fontWeight: 900, color: "#006cd3" }}>Login</h1>
          <p style={{ color: "#001529", fontWeight: 600 }}>
            Enter your admin credentials
          </p>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your Username!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{
                  width: "100%",
                  background: "#006cd3",
                  borderColor: "#006cd3",
                }}
              >
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
  notificationData: state.notification,
});

const mapDispatchToProps = (dispatch) => ({
  authenticate: (data) => dispatch(auth(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
