import {GET_CATEGORY,CREATE_OR_UPDATE_CATEGORY} from '../actions/category';
const category = (state = {
  data:[],
  selected:{},
  cascade:[],
  cascadeAdd:[],
  editData:{
    name: "",
    tags: "",
    category: [],
    id: false,
    material:""
  }
}, action) => {
  switch (action.type) {

    case GET_CATEGORY:

      state.data = action.data;

      state.cascade = action.data.map(cat => {

        return {
          ...cat,
          value : cat.cat_id,
          label : cat.name,
          key: cat.cat_id,
          name: cat.name,
          slug: cat.slug,
          is_active: cat.status,
          children : cat.subcat.length == 0 ? null : cat.subcat.map(subcat => {
            return {
              ...subcat,
              value : subcat.subcat_id,
              label : subcat.name,
              key: subcat.subcat_id,
              cat_id: cat.cat_id,
              name: subcat.name,
              slug: subcat.slug,
              is_active: subcat.status,
              children :  subcat.lowcat.length == 0 ? null :  subcat.lowcat.map(lowcat => {
                return {
                  ...lowcat,
                  value : lowcat.lowcat_id,
                  label : lowcat.name,
                  key: lowcat.lowcat_id,
                  cat_id: cat.cat_id,
                  subcat_id : subcat.subcat_id,
                  name: lowcat.name,
                  slug: lowcat.slug,
                  is_active: lowcat.status,
                  
                }
              })
              
            }
          })
        }

      });

      state.cascadeAdd = action.data.map(cat => {

        return {
          ...cat,
          value : cat.cat_id,
          label : cat.name,
          key: cat.cat_id,
          name: cat.name,
          slug: cat.slug,
          is_active: cat.status,
          children : cat.subcat.length == 0 ? null : cat.subcat.map(subcat => {
            return {
              ...subcat,
              value : subcat.subcat_id,
              label : subcat.name,
              key: subcat.subcat_id,
              name: subcat.name,
              slug: subcat.slug,
              is_active: subcat.status,
             
              
            }
          })
        }

      });

      return state;
    case 'SELECT_CATEGORY':
      state.selected = action.data;
      return state;
    case CREATE_OR_UPDATE_CATEGORY:
      state.error = null;
      state.message = action.message ? 'Created': 'Updated';

    case 'CATEGORY_EDIT':
        
        return {...state, editData: action.data};
        case 'CATEGORY_CREATE':
        
        return {...state, editData: {
          name: "",
          tags: "",
          category: [],
          id: false,
          material:""

        }};
        
    default:
      return state
  }
}
export default category