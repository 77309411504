import React, { useState } from 'react';
import { Form, Row, Col, Input } from 'antd';

import { Modal, Button } from 'antd';
import { connect } from "react-redux";
import {  Cascader, Select,Checkbox, AutoComplete,Upload,Space,Divider  } from 'antd';

const FilterModelCommon = ({visible, handleClose}) => {


  const [form] = Form.useForm();

  const onFinish = (values) => {
    handleClose(form.getFieldsValue());

  };

  const onOk = (values) => {


    handleClose(form.getFieldsValue());
  };



  return (
    <>
 
      <Modal title="Search" visible={visible} onOk={onOk} onCancel={() => handleClose()}>
      <Form
      form={form}
      name="advanced_search"
      className="ant-advanced-search-form"
      onFinish={onFinish}
    >
    
    <Form.Item
        name="query"
        label="keywords"
        
      >
        <Input placeholder="Search anything..." />
      </Form.Item>

      <Col span={4}>
                        <Button

                            style={{ marginTop: 30, display: 'none' }}
                            type="primary"
                            htmlType="submit"

                            size={"medium"}
                        />
                    </Col>
    </Form>
      </Modal>
    </>
  );
};


const mapStateToProps = (state) =>
   ({


  });

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterModelCommon);