import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  useDymoCheckService,
  useDymoRenderLabel,
  buildDymoRequest,
  useDymoFetchPrinters,
  getDymoPrintersFromXml,
} from "react-dymo";
import { printLabel } from "react-dymo-hooks";
import { Button, PageHeader, Spin, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

export const PageDymo = (props) => {
  const [xmlData, setXmlData] = useState();
  const statusDymoService = useDymoCheckService();

  const printers = useDymoFetchPrinters();

  const render = useDymoRenderLabel({
    onSuccess: (w) => {
      console.log(w, "successOpenLabel");
    },
    onError: (w) => {
      console.log(w, "errorRenderLabel");
    },
  });

  const handleUpload = (file) => {
    // Assuming you're interested in the first file uploaded

    if (file) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const xmlString = event.target.result;

        // Parse XML

        console.log(xmlString);
        setXmlData(xmlString);
      };
      reader.readAsText(file);
    }
  };

  const handlePrint = () => {
    console.log("print request sent on ", printers.data?.[0]?.modelName);
    message.success("print request sent on ", printers.data?.[0]?.modelName);
    printLabel(printers.data?.[0]?.modelName, xmlData);
  };

  useEffect(() => {
    render.renderLabel(xmlData);
  }, [xmlData]);

  if (printers.status === "loading" || statusDymoService.status === "loading") {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spin />
      </div>
    );
  }

  if (printers.data.length == 0) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p>No printers connected to the service</p>
      </div>
    );
  }

  return (
    <div>
      <PageHeader
        title="Printer"
        className="site-page-header"
        extra={[
          <Upload
            accept=".xml"
            multiple={false}
            action={handleUpload}
            showUploadList={false}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />} type="primary">
              Upload XML
            </Button>
          </Upload>,
        ]}
      ></PageHeader>

      {xmlData && render.imageLabel && (
        <div
          style={{
            display: "flex",
            rowGap: 16,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img src={render.imageLabel} alt="dymo label preview" />
          <Button onClick={handlePrint}>Print</Button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PageDymo);
