import { Form, Input, Modal } from "antd";
import { useEffect } from "react";

const ModalAddVendor = ({ visible, formData, handleClose }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      if (formData) form.setFieldsValue(formData);
      else form.resetFields();
    }
  }, [visible]);

  const onFinish = (values) => {
    handleClose(form.getFieldsValue(), !!formData);
  };

  return (
    <>
      <Modal
        title="Add vendor"
        open={visible}
        onCancel={() => handleClose()}
        okText={formData ? "Save" : "Add"}
        onOk={form.submit}
      >
        <Form
          form={form}
          className="ant-advanced-search-form"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item name="uniId" hidden={true}></Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Enter email",
              },
            ]}
          >
            <Input placeholder="email" />
          </Form.Item>

          <Form.Item
            name="company"
            label="Company name"
            rules={[
              {
                required: true,
                message: "Enter company name",
              },
            ]}
          >
            <Input placeholder="company" />
          </Form.Item>

          <Form.Item name="name" label="Name">
            <Input placeholder="name" />
          </Form.Item>

          <Form.Item name="mobile" label="Mobile">
            <Input placeholder="mobile" />
          </Form.Item>

          <Form.Item name="address" label="Address">
            <Input placeholder="address" />
          </Form.Item>

          <Form.Item name="tax" label="Tax">
            <Input placeholder="Tax" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ModalAddVendor;
