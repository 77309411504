
const orders = (state = {list:[], isDetail : false}, action) => {
  switch (action.type) {

    case 'ORDER_LIST':

      state.list = action.data;
      return state;

      case 'ORDER_VIEW':

        state.view = action.data;
        return state;
    default:
      return state
  }
}
export default orders