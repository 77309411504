import React, { useState, useEffect } from "react";

import { connect } from "react-redux";

import {
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  Divider,
  Spin,
  notification,
  message,
  InputNumber,
} from "antd";

import { CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";

import {
  PlusOutlined,
  MinusCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";

import { Space } from "antd";

import SectionUpload from "../component/SectionUpload";
import { SectionTags } from "../component/SectionTags";

import { Cascader } from "antd";
import { PageHeader } from "antd";
import { useHistory } from "react-router-dom";

import update from "immutability-helper";

import {
  syncEtsy,
  syncShopify,
  syncEtsyGold,
  syncShopifyTJ,
} from "../actions/sync";
import {
  createOrUpdateUnit,
  deleteUnit,
  updateVendor,
  fetchCms,
  addCMS,
  deleteCMS,
  fetchVendor,
} from "../actions/category";

import { putData, createData, fetchDataById } from "../actions/product";
import DragListView from "react-drag-listview";
import SingleUpload from "../component/SingleUpload";
import SyncBox from "../component/SyncBox";
import { useParams } from "react-router-dom";
import ModalAddVendor from "../component/modal/ModalAddVendor";

const { Option } = Select;
const { TextArea } = Input;
function PageAddProduct({
  getData,
  addUnit,
  removeUnit,
  updateVendor,
  unitData,
  typeData,
  vendorData,
  updateProduct,
  syncOnEtsy,
  PRODUCT_REFRESH,
  CMS_REFRESH,
  syncOnShopify,
  syncOnEtsyGold,
  syncOnShopifyTJ,
  cascadeCategory,
  createProduct,
  getCms,
  cmsData,
  addCms,
  removeCms,
  getVendors,
  defaultAttributes = [],
}) {
  const [loading, setLoading] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  const [attributeList, setAttributeList] = useState([]);

  const showDelete = !!parseInt(process.env.REACT_APP_SHOW_DELETE);

  const history = useHistory();

  const onEditClicked = (data) => {
    console.log("edit clicked", data);

    const productData = data;
    return {
      discount: 0,
      name: productData.name,
      model: productData.model,
      sku: productData.sku,
      description: productData.description,
      category: getCategoryArray(data),
      categoryUni: getCategoryUniArray(data),
      price: getFirstVariantData(data)["mrp"],
      discount: getFirstVariantData(data)["discount_percent"],
      variant: getVariantData(data),
      tags: !!data?.tags?.[0]?.tags
        ? data?.tags?.[0]?.tags?.split(",")
        : undefined,
      material: data?.material,
      unit: productData.unit_meta.unit_id,
      slug: productData.slug,
      images: productData.images,
      uniId: productData.product_id,
      isFormEdit: true,
      etsy_gold_listing_id: productData.etsy_gold_listing_id,
      etsy_listing_id: productData.etsy_listing_id,
      shopify_id: productData.shopify_id,
      shopify_tj_id: productData.shopify_tj_id,
      status: productData.status,
      vendor_id: productData?.vendor?.find((v) => v.status)?.vendor_id,
      product_type: productData.product_type,
      // attribute: productData.attributes,
      // variantMeta: generateVariantMeta(productData.variant),
    };
  };

  function mergeAttributes(items, oldItems) {
    let mergedItems = [];

    const newItems = items.filter((item) => !!item.value);
    // Iterate through newItems array
    newItems.forEach((newItem) => {
      // Check if newItem has attribute_id
      if (newItem.attribute_id) {
        // Check if oldItems has an item with the same attribute_id
        const oldItemIndex = oldItems.findIndex(
          (oldItem) => oldItem.attribute_id === newItem.attribute_id
        );
        if (oldItemIndex !== -1) {
          // Merge keys of oldItem and newItem
          const mergedItem = {
            ...oldItems[oldItemIndex],
            ...newItem,
          };
          mergedItems.push(mergedItem);
          // Remove the used item from oldItems array
          oldItems.splice(oldItemIndex, 1);
        } else {
          // If oldItems does not have an item with the same attribute_id
          newItem.status = true;
          newItem.is_new = true;
          newItem.product_id = productId === "add" ? undefined : productId;
          mergedItems.push(newItem);
        }
      } else {
        // If newItem does not have attribute_id
        newItem.status = true;
        newItem.is_new = true;
        newItem.product_id = productId === "add" ? undefined : productId;
        mergedItems.push(newItem);
      }
    });

    // Add remaining items from oldItems array
    oldItems.forEach((oldItem) => {
      oldItem.status = false;
      mergedItems.push(oldItem);
    });
    return mergedItems;
  }

  function mergeArrays(array1, array2) {
    // Create a map to store the merged array elements
    const mergedMap = new Map();

    // Add elements from the first array to the map
    array1.forEach((item) => {
      const item2 = array2.find((i) => i.key === item.key);
      console.log(item2, "item2");
      mergedMap.set(item.key, item2 ? { ...item, ...item2 } : item);
    });

    // Add elements from the second array to the map, overwriting existing ones if any
    return Array.from(mergedMap.values());
  }

  const [init, setInit] = useState(true);

  const [form] = Form.useForm();

  const { productId } = useParams();
  const fetchData = async () => {
    setLoading(true);
    if (productId === "add") {
      if (init) {
        await Promise.all([getCms({ limit: 1000 }), getVendors()]);
      }
      form.resetFields();
      setFileList([]);
    } else {
      const promiseArray = [getData(productId)];
      if (init) {
        promiseArray.push(getCms({ limit: 1000 }));
        promiseArray.push(getVendors());
      }
      let [data] = await Promise.all(promiseArray);
      const editData = onEditClicked(data);

      const variant = editData?.variant?.map((item) => {
        const url = editData?.images?.find(
          (image) => image.img_id === item.img_id
        )?.url;
        return { ...item, url };
      });
      setEditData(editData);
      setAttributeList(data?.attributes ? data?.attributes : []);
      setTagList(data?.tags);
      setMaterialList(editData?.material);
      form.setFieldsValue({ ...editData, variant });
      setFileList(
        editData?.images?.map((img) => {
          return {
            ...img,
            uid: img.img_id,
            status: "done",
            is_new: false,
          };
        })
      );
    }
    setInit(false);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [PRODUCT_REFRESH]);

  useEffect(() => {
    if (defaultAttributes)
      form.setFieldValue(
        "attribute",
        mergeArrays(defaultAttributes, attributeList ?? [])
      );
  }, [defaultAttributes, attributeList]);

  useEffect(() => {
    if (!init) getCms({ limit: 1000 });
  }, [CMS_REFRESH]);

  const onFinish = async (values) => {
    const productData = editData;
    console.log(values);

    let filterterImage =
      imageList
        ?.map((file) => {
          if (file.percent == 100) {
            return {
              img_id: file.response["img_id"],
              name: file.response["name"],
              path: file.response["path"],
              url: file.response["url"],
              type: file.response["filetype"],
              status: "done",
              is_new: true,
            };
          } else if (file.percent == undefined && file.status == "done") {
            return file;
          } else {
            return file;
          }
        })
        .filter((file) => file) ?? [];

    console.log(filterterImage);

    filterterImage = [
      ...filterterImage,
      ...removedImages.map((item) => {
        return { ...item, status: "removed" };
      }),
    ];

    let removedVariant =
      getVariantData(editData)
        ?.filter(
          (item) =>
            values.variant.filter(
              (i) =>
                i.product_to_variants_id &&
                i.product_to_variants_id == item.product_to_variants_id
            ).length == 0
        )
        .map((item) => {
          return {
            ...item,
            status: false,
          };
        }) ?? [];
    console.log("removed variant", removedVariant);
    let finalVariants = values?.variant
      ? [...values?.variant, ...removedVariant]
      : [...removedVariant];

    const filteredAttribute = values?.attribute
      ? mergeAttributes(values?.attribute, attributeList)
      : null;

    let payloadProduct = {
      product_id: productData?.uniId,
      ...values,
      attribute: filteredAttribute,
      variant: finalVariants,
      tags:
        tagList?.length > 0
          ? [{ ...tagList[0], tags: values.tags ? values.tags.join(",") : "" }]
          : [{ tags: values.tags ? values.tags.join(",") : "" }],
      material: materialList,
      images: filterterImage,
      ...{
        [values.category[0] != null]: {
          category: [
            {
              ...productData?.category?.[0],
              cat_id: values.category[0] ?? null,
              uni_id: productData?.categoryUni?.[0] ?? null,
            },
          ],
        },
      }.true,
      ...{
        [values.category[1] != null]: {
          subcategory: [
            {
              ...productData?.subcategory?.[0],

              subcat_id: values.category[1],
              uni_id: productData?.categoryUni?.[1] ?? null,
            },
          ],
        },
      }.true,
      ...{
        [values.category[2] != null]: {
          lowcategory: [
            {
              ...productData?.lowcategory?.[0],

              lowcat_id: values.category[2] ?? null,
              uni_id: productData?.categoryUni?.[2] ?? null,
            },
          ],
        },
      }.true,
    };

    if (!payloadProduct.discount) {
      payloadProduct = {
        ...payloadProduct,
        discount: 0,
      };
    }

    if (productId === "add") {
      // payloadProduct.variant  = [productData.variant[0], ...payloadProduct.variant] ;
      const createdData = await createProduct(payloadProduct);
      if (createdData)
        history.push(`/admin/products/${createdData.product_id}`);
    } else {
      updateProduct(productData.uniId, payloadProduct);
    }
  };

  const [imageList, setFileList] = useState([]);

  const [removedImages, setRemovedImages] = useState([]);

  // const variantData = Form.useWatch("variantMeta", form);

  const [tagList, setTagList] = useState(editData?.tags);
  const [materialList, setMaterialList] = useState([]);
  const [showVendorModal, setShowVendorModal] = useState({ show: false });

  const handleVendorSubmit = async (values, isEdit) => {
    if (values) {
      if (isEdit) await updateVendor(values.uniId, values);
      else await addUnit(values, true);
    }
    setShowVendorModal({ show: false });
  };

  const handleChange = ({ fileList }) => {
    console.log("handle Image change");
    console.log(fileList);
    setFileList(fileList);
  };

  const onDragEnd = (fromIndex, toIndex) => {
    const newFieldsValue = [...form.getFieldValue("variant")];

    const movedItem = newFieldsValue[fromIndex];
    newFieldsValue.splice(fromIndex, 1);
    newFieldsValue.splice(toIndex, 0, movedItem);
    form.setFieldsValue({ variant: newFieldsValue });
  };

  const normFile = (e) => {
    console.log("Upload event:", e);

    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const handleImageDnd = (dragIndex, hoverIndex) => {
    console.log(dragIndex, hoverIndex);

    const dragRow = imageList[dragIndex];

    setFileList(
      update(imageList, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      })
    );
  };

  const handleRemoveChange = (file) => {
    console.log(file, editData?.isFormEdit);
    if (editData?.isFormEdit) {
      setRemovedImages([...removedImages, file]);
    }
  };

  const getCategoryArray = (data) => {
    let cat = [];
    if (data.category.length > 0) {
      cat.push(data.category[0].cat_id);
    }

    if (data.subcategory.length > 0) {
      cat.push(data.subcategory[0].subcat_id);
    }

    if (data.lowcategory.length > 0) {
      cat.push(data.lowcategory[0].lowcat_id);
    }

    return cat;
  };

  const getCategoryUniArray = (data) => {
    let cat = [];
    if (data.category.length > 0) {
      cat.push(data.category[0].uni_id);
    }

    if (data.subcategory.length > 0) {
      cat.push(data.subcategory[0].uni_id);
    }

    if (data.lowcategory.length > 0) {
      cat.push(data.lowcategory[0].uni_id);
    }

    return cat;
  };

  const getFirstVariantData = (data) => {
    let variant = {};
    if (data?.variant?.length >= 1) {
      variant = data.variant[0];
    }
    return variant;
  };

  const getVariantData = (data) => {
    // if (productData.variant.length == 1) return [];
    let variant = data?.variant?.filter(
      (item) =>
        (item.title_1 && item.title_1 != "" && item.title_1 != null) || true
    );

    console.log(variant);

    return variant;
  };

  function generateCombinations(array) {
    const input = array.filter(
      (i) =>
        !!i &&
        !!i?.value &&
        i?.value?.length > 0 &&
        !!i?.value?.[0] &&
        !!i?.type
    );

    if (input?.length === 0) return [];

    const combinations = [];
    const variants = form.getFieldValue("variant");

    function generateRecursiveCombinations(index, currentCombination) {
      if (index === input.length) {
        combinations.push(currentCombination);
        return;
      }

      const currentItem = input[index];
      const { type, value } = currentItem;

      for (const val of value) {
        if (!!val) {
          const updatedCombination = {
            ...currentCombination,
            [`var_type_${index + 1}`]: type,
            [`title_${index + 1}`]: val,
          };
          generateRecursiveCombinations(index + 1, updatedCombination);
        }
      }
    }

    generateRecursiveCombinations(0, {});

    for (let combination of combinations) {
      const variant = variants?.find((item, index) => {
        for (let i = 0; i < input.length; i++) {
          if (
            item[`var_type_${i + 1}`] !== combination[`var_type_${i + 1}`] ||
            item[`title_${i + 1}`] !== combination[`title_${i + 1}`]
          ) {
            return false;
          }
        }
        return true;
      });

      if (variant) {
        combinations[combinations.indexOf(combination)] = {
          ...variant,
          ...combination,
        };
      }
    }

    return combinations;
  }

  function generateVariantMeta(input) {
    let meta = [];

    const objData = {};

    for (const variant of input) {
      if (variant?.var_type_1) {
        if (objData[variant.var_type_1]) {
          objData[variant.var_type_1].push(variant.title_1);
        } else {
          objData[variant.var_type_1] = [variant.title_1];
        }
      }

      if (variant?.var_type_2) {
        if (objData[variant.var_type_2]) {
          objData[variant.var_type_2].push(variant.title_2);
        } else {
          objData[variant.var_type_2] = [variant.title_2];
        }
      }

      if (variant?.var_type_3) {
        if (objData[variant.var_type_3]) {
          objData[variant.var_type_3].push(variant.title_3);
        } else {
          objData[variant.var_type_3] = [variant.title_3];
        }
      }
    }
    meta = Object.entries(objData).map(([type, value]) => ({ type, value }));
    return meta;
  }

  const attributesData = Form.useWatch("attribute", form);

  // useEffect(() => {
  //   if (variantData) {
  //     form.setFieldValue("variant", generateCombinations(variantData));
  //   }
  // }, [variantData]);

  const getProductMaterial = (data) => {
    return [];
    if (data.material.length == 0) return [];
    let tags = data.material[0].tags.split(",");

    console.log(data);
    return tags;
  };

  const [newItem, addNewItem] = useState("");
  const onNameChange = (event) => {
    addNewItem(event.target.value);
  };

  const addItem = (data, type) => {
    console.log(data);

    if (data != "") addUnit({ name: data }, type);

    addNewItem("");
  };

  const addType = (entity) => {
    if (!newItem || newItem === "") return;
    addCms(newItem, entity);
    addNewItem("");
  };

  const handleProductSync = (data, type) => {
    if (type === "etsy_listing_id") {
      syncOnEtsy(data.uniId);
    } else if (type === "etsy_gold_listing_id") {
      syncOnEtsyGold(data.uniId);
    } else if (type === "shopify_id") {
      syncOnShopify(data.uniId);
    } else if (type === "shopify_tj_id") {
      syncOnShopifyTJ(data.uniId);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <div className="site-page-header-ghost-wrapper">
      <PageHeader
        title="Add Product"
        className="site-page-header"
        extra={[
          <Button
            key="3"
            onClick={() => {
              form.submit();
            }}
          >
            Save
          </Button>,
        ]}
      ></PageHeader>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        style={{ marginBottom: 16 }}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="isFormEdit" hidden={true}></Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="uniId" hidden={true}></Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="baseId" hidden={true}></Form.Item>
          </Col>

          <Col
            span={18}
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: 16,
            }}
          >
            <div className="add-section">
              <Form.Item name="name" label="Product Name">
                <Input />
              </Form.Item>
              <Form.Item name="description" label="Description">
                <TextArea
                  value={""}
                  onChange={() => {}}
                  placeholder=""
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>
            </div>
            <div className="add-section">
              <Form.Item
                name="upload"
                label="Media"
                valuePropName="fileList"
                value={imageList}
                getValueFromEvent={normFile}
                extra=""
              >
                <SectionUpload
                  imageList={imageList}
                  handleChange={handleChange}
                  handleRemoveChange={handleRemoveChange}
                  handleImageDnd={handleImageDnd}
                />
              </Form.Item>
            </div>
            <div className="add-section">
              <Form.Item label="Variants">
                <Form.List name="variant">
                  {(fields, { add, remove }) =>
                    console.log(fields, "fields") || (
                      <>
                        <DragListView
                          onDragEnd={onDragEnd}
                          nodeSelector="[data-id]" // Change this selector to match your FormList.Item's container
                        >
                          {fields.map((field, index) => (
                            <Space
                              key={field.key}
                              style={{
                                display: "flex",
                                justifyContent: "stretch",
                                alignItems: "center",
                                marginBottom: 8,
                                cursor: "move",
                              }}
                              align="baseline"
                              data-id={`item-${index}`}
                            >
                              <Space>
                                <Form.Item
                                  {...field}
                                  style={{ width: 100 }}
                                  label={"Image"}
                                  name={[field.name, "url"]}
                                  fieldKey={[field.fieldKey, "image"]}
                                >
                                  <SingleUpload
                                    fileList={imageList}
                                    setFileList={setFileList}
                                    showImagePickerModel={
                                      imageList?.length !== 0
                                    }
                                  />
                                </Form.Item>
                                <Space direction="vertical" size="small">
                                  {/* <Row gutter={16} style={{ width: "100%" }}> */}

                                  <Space style={{ paddingRight: 16 }}>
                                    {/* <Col span={4}>
                                      <Form.Item
                                        style={{ marginBottom: 0 }}
                                        {...field}
                                        label={"Name"}
                                        name={[field.name, "title_1"]}
                                        fieldKey={[field.fieldKey, "title_1"]}
                                      >
                                        <Input placeholder="Name" />
                                      </Form.Item>
                                    </Col> */}
                                    {/* <Col span={4}> */}
                                    <Form.Item
                                      style={{ marginBottom: 0 }}
                                      {...field}
                                      label={"Price"}
                                      name={[field.name, "mrp"]}
                                      fieldKey={[field.fieldKey, "mrp"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing mrp ",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        style={{ width: "100%" }}
                                        placeholder="Price"
                                      />
                                    </Form.Item>
                                    {/* </Col>
                                    <Col span={4}> */}
                                    <Form.Item
                                      {...field}
                                      style={{ marginBottom: 0 }}
                                      label={"Discount"}
                                      name={[field.name, "discount_percent"]}
                                      fieldKey={[
                                        field.fieldKey,
                                        "discount_percent",
                                      ]}
                                    >
                                      <InputNumber
                                        style={{ width: "100%" }}
                                        placeholder="Discount"
                                      />
                                    </Form.Item>
                                    {/* </Col>
                                    <Col span={4}> */}
                                    <Form.Item
                                      {...field}
                                      style={{ marginBottom: 0 }}
                                      label={"Quantity"}
                                      name={[field.name, "quantity"]}
                                      fieldKey={[field.fieldKey, "quantity"]}
                                    >
                                      <InputNumber
                                        style={{ width: "100%" }}
                                        placeholder="Quantity"
                                      />
                                    </Form.Item>
                                    {/* </Col>
                                    <Col span={4}> */}
                                    <Form.Item
                                      {...field}
                                      style={{ marginBottom: 0 }}
                                      label={"SKU"}
                                      name={[field.name, "sku"]}
                                      fieldKey={[field.fieldKey, "sku"]}
                                    >
                                      <Input placeholder="sku" />
                                    </Form.Item>
                                    {/* </Col>
                                    <Col span={4}> */}
                                    <Form.Item
                                      {...field}
                                      label={"Min Quantity"}
                                      style={{ marginBottom: 0 }}
                                      name={[field.name, "q_notify"]}
                                      fieldKey={[field.fieldKey, "q_notify"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Missing min quantity ",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        style={{ width: "100%" }}
                                        placeholder="10"
                                      />
                                    </Form.Item>
                                    {/* </Col> */}
                                  </Space>
                                  {/* </Row> */}
                                  <Row gutter={16} style={{ width: "100%" }}>
                                    <Col span={4}>
                                      <Form.Item
                                        {...field}
                                        label={"Type 1"}
                                        name={[field.name, "var_type_1"]}
                                        fieldKey={[
                                          field.fieldKey,
                                          "var_type_1",
                                        ]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Missing Type ",
                                          },
                                        ]}
                                      >
                                        <Select
                                          style={{ width: "100%" }}
                                          placeholder="Type"
                                          allowClear
                                          showSearch
                                          onClear={() =>
                                            form.resetFields([
                                              [
                                                "variant",
                                                field.name,
                                                "title_1",
                                              ],
                                            ])
                                          }
                                          dropdownRender={(menu) => (
                                            <div>
                                              {menu}
                                              <Divider
                                                style={{ margin: "4px 0" }}
                                              />
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  padding: 8,
                                                }}
                                              >
                                                <Input
                                                  style={{ flex: "auto" }}
                                                  value={newItem}
                                                  onChange={onNameChange}
                                                />
                                                <a
                                                  style={{
                                                    flex: "none",
                                                    padding: "8px",
                                                    display: "block",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    addType(
                                                      "dim.product.variant.option"
                                                    )
                                                  }
                                                >
                                                  <PlusOutlined /> Add
                                                </a>
                                              </div>
                                            </div>
                                          )}
                                        >
                                          {cmsData
                                            ?.filter(
                                              (i) =>
                                                i.status &&
                                                i.entity_id ===
                                                  "dim.product.variant.option"
                                            )
                                            ?.map((item) => (
                                              <Option
                                                key={item.cms_id}
                                                value={item.data}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <span>{item.data}</span>
                                                  {showDelete && (
                                                    <CloseOutlined
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        removeCms(item.cms_id);
                                                      }}
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    />
                                                  )}
                                                </div>
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                      <Form.Item
                                        {...field}
                                        label={"Title 1"}
                                        name={[field.name, "title_1"]}
                                        fieldKey={[field.fieldKey, "title_1"]}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Missing Title ",
                                          },
                                        ]}
                                      >
                                        <Input placeholder="type" />
                                      </Form.Item>
                                    </Col>

                                    <Col span={4}>
                                      <Form.Item
                                        {...field}
                                        label={"Type 2"}
                                        name={[field.name, "var_type_2"]}
                                        fieldKey={[
                                          field.fieldKey,
                                          "var_type_2",
                                        ]}
                                      >
                                        <Select
                                          style={{ width: "100%" }}
                                          placeholder="Type"
                                          onClear={() =>
                                            form.resetFields([
                                              [
                                                "variant",
                                                field.name,
                                                "title_2",
                                              ],
                                            ])
                                          }
                                          allowClear
                                          showSearch
                                          dropdownRender={(menu) => (
                                            <div>
                                              {menu}
                                              <Divider
                                                style={{ margin: "4px 0" }}
                                              />
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  padding: 8,
                                                }}
                                              >
                                                <Input
                                                  style={{ flex: "auto" }}
                                                  value={newItem}
                                                  onChange={onNameChange}
                                                />
                                                <a
                                                  style={{
                                                    flex: "none",
                                                    padding: "8px",
                                                    display: "block",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    addType(
                                                      "dim.product.variant.option"
                                                    );
                                                  }}
                                                >
                                                  <PlusOutlined /> Add
                                                </a>
                                              </div>
                                            </div>
                                          )}
                                        >
                                          {cmsData
                                            ?.filter(
                                              (i) =>
                                                i.status &&
                                                i.entity_id ===
                                                  "dim.product.variant.option"
                                            )
                                            ?.map((item) => (
                                              <Option
                                                key={item.cms_id}
                                                value={item.data}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <span>{item.data}</span>
                                                  {showDelete && (
                                                    <CloseOutlined
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        removeCms(item.cms_id);
                                                      }}
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    />
                                                  )}
                                                </div>
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                      <Form.Item
                                        {...field}
                                        label={"Title 2"}
                                        name={[field.name, "title_2"]}
                                        fieldKey={[field.fieldKey, "title_2"]}
                                      >
                                        <Input placeholder="type" />
                                      </Form.Item>
                                    </Col>

                                    <Col span={4}>
                                      <Form.Item
                                        {...field}
                                        label={"Type 3"}
                                        name={[field.name, "var_type_3"]}
                                        fieldKey={[
                                          field.fieldKey,
                                          "var_type_3",
                                        ]}
                                      >
                                        <Select
                                          style={{ width: "100%" }}
                                          placeholder="Type"
                                          allowClear
                                          showSearch
                                          onClear={() =>
                                            form.resetFields([
                                              [
                                                "variant",
                                                field.name,
                                                "title_3",
                                              ],
                                            ])
                                          }
                                          dropdownRender={(menu) => (
                                            <div>
                                              {menu}
                                              <Divider
                                                style={{ margin: "4px 0" }}
                                              />
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  padding: 8,
                                                }}
                                              >
                                                <Input
                                                  style={{ flex: "auto" }}
                                                  value={newItem}
                                                  onChange={onNameChange}
                                                />
                                                <a
                                                  style={{
                                                    flex: "none",
                                                    padding: "8px",
                                                    display: "block",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    addType(
                                                      "dim.product.variant.option"
                                                    );
                                                  }}
                                                >
                                                  <PlusOutlined /> Add
                                                </a>
                                              </div>
                                            </div>
                                          )}
                                        >
                                          {cmsData
                                            ?.filter(
                                              (i) =>
                                                i.status &&
                                                i.entity_id ===
                                                  "dim.product.variant.option"
                                            )
                                            ?.map((item) => (
                                              <Option
                                                key={item.cms_id}
                                                value={item.data}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <span>{item.data}</span>
                                                  {showDelete && (
                                                    <CloseOutlined
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        removeCms(item.cms_id);
                                                      }}
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    />
                                                  )}
                                                </div>
                                              </Option>
                                            ))}
                                        </Select>
                                      </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                      <Form.Item
                                        {...field}
                                        label={"Title 3"}
                                        name={[field.name, "title_3"]}
                                        fieldKey={[field.fieldKey, "title_3"]}
                                      >
                                        <Input placeholder="type" />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Space>
                              </Space>

                              <MinusCircleOutlined
                                onClick={() => remove(field.name)}
                              />
                            </Space>
                          ))}
                        </DragListView>
                        <Form.Item style={{ margin: 0 }}>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Variants
                          </Button>
                        </Form.Item>
                      </>
                    )
                  }
                </Form.List>
              </Form.Item>
            </div>
            <div className="add-section">
              <Form.Item label="Attributes">
                <Form.List name="attribute">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => {
                        return (
                          <Space
                            key={field.key}
                            style={{
                              width: "100%",
                              marginBottom: 8,
                            }}
                            align="baseline"
                            data-id={`item-${index}`}
                            className="type-space"
                          >
                            <Space
                              direction="vertical"
                              style={{ width: "100%" }}
                            >
                              <Space.Compact
                                block
                                style={{ display: "flex", columnGap: 16 }}
                              >
                                <Form.Item
                                  {...field}
                                  style={{ width: "100%", margin: 0 }}
                                  name={[field.name, "key"]}
                                  fieldKey={[field.fieldKey, "key"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Missing option ",
                                    },
                                  ]}
                                >
                                  {attributesData?.[index]?.key}
                                </Form.Item>
                                <Form.Item
                                  {...field}
                                  style={{ width: "100%", margin: 0 }}
                                  name={[field.name, "type"]}
                                  fieldKey={[field.fieldKey, "type"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Missing Data Type ",
                                    },
                                  ]}
                                  hidden={true}
                                ></Form.Item>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "product_id"]}
                                  fieldKey={[field.fieldKey, "product_id"]}
                                  hidden={true}
                                ></Form.Item>
                                <Form.Item
                                  {...field}
                                  name={[field.name, "attribute_id"]}
                                  fieldKey={[field.fieldKey, "attribute_id"]}
                                  hidden={true}
                                ></Form.Item>
                                {(!attributesData?.[index] ||
                                  !attributesData?.[index]?.type ||
                                  attributesData?.[index]?.type ===
                                    "string") && (
                                  <Form.Item
                                    {...field}
                                    style={{ width: "100%", margin: 0 }}
                                    name={[field.name, "value"]}
                                    fieldKey={[field.fieldKey, "value"]}
                                  >
                                    <Input placeholder="value" />
                                  </Form.Item>
                                )}
                                {attributesData?.[index]?.type === "number" && (
                                  <Form.Item
                                    {...field}
                                    style={{ width: "100%", margin: 0 }}
                                    name={[field.name, "value"]}
                                    fieldKey={[field.fieldKey, "value"]}
                                  >
                                    <InputNumber
                                      style={{ width: "100%" }}
                                      placeholder="value"
                                    />
                                  </Form.Item>
                                )}
                                {attributesData?.[index]?.type ===
                                  "boolean" && (
                                  <Form.Item
                                    {...field}
                                    style={{ width: "100%", margin: 0 }}
                                    name={[field.name, "value"]}
                                    fieldKey={[field.fieldKey, "value"]}
                                  >
                                    <Select
                                      placeholder="value"
                                      options={[
                                        { label: "True", value: true },
                                        { label: "False", value: false },
                                      ]}
                                    />
                                  </Form.Item>
                                )}
                              </Space.Compact>
                            </Space>
                          </Space>
                        );
                      })}
                    </>
                  )}
                </Form.List>
              </Form.Item>
              <p className="add-section-heading">Categorization</p>
              <Form.Item
                name="category"
                label="Category Info"
                rules={[
                  {
                    type: "array",
                    required: true,
                    message: "Please select product category!",
                  },
                ]}
              >
                <Cascader
                  options={cascadeCategory}
                  changeOnSelect
                  showSearch={true}
                />
              </Form.Item>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item name="tags" label="Tags">
                    <Select
                      mode="multiple"
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      placeholder="Please select"
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              padding: 8,
                            }}
                          >
                            <Input
                              style={{ flex: "auto" }}
                              value={newItem}
                              onChange={onNameChange}
                            />
                            <a
                              style={{
                                flex: "none",
                                padding: "8px",
                                display: "block",
                                cursor: "pointer",
                              }}
                              onClick={() => addType("dim.product.tags")}
                            >
                              <PlusOutlined /> Add
                            </a>
                          </div>
                        </div>
                      )}
                    >
                      {cmsData
                        ?.filter(
                          (i) => i.status && i.entity_id === "dim.product.tags"
                        )
                        ?.map((item) => (
                          <Option key={item.cms_id} value={item.data}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <span>{item.data}</span>
                              {showDelete && (
                                <CloseOutlined
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeCms(item.cms_id);
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                            </div>
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="material"
                    label="Material"
                    value={materialList}
                  >
                    <SectionTags
                      tagList={
                        materialList?.length > 0
                          ? materialList?.[0]?.tags
                              ?.split(",")
                              ?.filter((item) => item != "")
                          : []
                      }
                      handleChange={(tags) =>
                        setMaterialList((prev) => {
                          const prevData = [...prev];
                          return prevData?.length > 0
                            ? [
                                {
                                  ...prevData[0],
                                  tags: tags ? tags.join(",") : "",
                                },
                              ]
                            : [
                                {
                                  tags: tags ? tags.join(",") : "",
                                },
                              ];
                        })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col
            span={6}
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: 16,
            }}
          >
            <div className="add-section">
              <p className="add-section-heading">Publish</p>

              <SyncBox data={editData} handleProductSync={handleProductSync} />
            </div>
            <div className="add-section">
              {/* <p className="add-section-heading">Status</p> */}

              <Form.Item
                name="status"
                label="Status"
                rules={[
                  {
                    required: true,
                    message: "Please select status!",
                  },
                ]}
              >
                <Select
                  // style={{ width: '100%' }}

                  options={[
                    {
                      value: true,
                      label: "Active",
                    },
                    {
                      value: false,
                      label: "Inactive",
                    },
                  ]}
                />
              </Form.Item>
            </div>
            <div className="add-section">
              <p className="add-section-heading">Product Organization</p>
              <Form.Item
                name="model"
                label="Item No"
                rules={[
                  {
                    required: true,
                    message: "Please input model no!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="unit"
                label="Product Unit"
                rules={[
                  {
                    required: true,
                    message: "Please input product unit!",
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Item unit"
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8,
                        }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          value={newItem}
                          onChange={onNameChange}
                        />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            addItem(newItem);
                          }}
                        >
                          <PlusOutlined /> Add Unit
                        </a>
                      </div>
                    </div>
                  )}
                >
                  {unitData
                    ?.filter((i) => i.status)
                    ?.map((item) => (
                      <Option key={item.unit_id} value={item.unit_id}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span>{item.name}</span>
                          {showDelete && (
                            <CloseOutlined
                              onClick={(e) => {
                                e.stopPropagation();
                                removeUnit(item.unit_id);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </div>
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="product_type"
                label="Product Type"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please input product type!",
                //   },
                // ]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Product type"
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8,
                        }}
                      >
                        <Input
                          style={{ flex: "auto" }}
                          value={newItem}
                          onChange={onNameChange}
                        />
                        <a
                          style={{
                            flex: "none",
                            padding: "8px",
                            display: "block",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            addType("dim.product.product_type");
                          }}
                        >
                          <PlusOutlined /> Add Type
                        </a>
                      </div>
                    </div>
                  )}
                >
                  {cmsData
                    ?.filter(
                      (i) =>
                        i.status && i.entity_id === "dim.product.product_type"
                    )
                    ?.map((item) => (
                      <Option key={item.cms_id} value={item.data}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span>{item.data}</span>
                          {showDelete && (
                            <CloseOutlined
                              onClick={(e) => {
                                e.stopPropagation();
                                removeCms(item.cms_id);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </div>
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="vendor_id"
                label="Vendor"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select vendor",
                //   },
                // ]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select Vendor"
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          padding: 8,
                        }}
                      >
                        <Button
                          style={{ width: "100%" }}
                          onClick={() => setShowVendorModal({ show: true })}
                          type="primary"
                        >
                          Add Vendor
                        </Button>
                      </div>
                    </div>
                  )}
                >
                  {vendorData
                    ?.filter((i) => i.status)
                    ?.map((item) => (
                      <Option key={item.user_id} value={item.user_id}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span>{item.company}</span>
                          <Space>
                            <EditOutlined
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowVendorModal({
                                  show: true,
                                  formData: { ...item, uniId: item?.user_id },
                                });
                              }}
                              style={{ cursor: "pointer" }}
                            />
                            {showDelete && (
                              <CloseOutlined
                                onClick={(e) => {
                                  e.stopPropagation();
                                  updateVendor(item.user_id, {
                                    ...item,
                                    status: false,
                                  });
                                }}
                                style={{ cursor: "pointer", color: "red" }}
                              />
                            )}
                          </Space>
                        </div>
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item name="slug" label="Slug">
                <Input />
              </Form.Item>
              <Form.Item name="sku" label="SKU">
                <Input />
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
      <ModalAddVendor
        visible={showVendorModal?.show}
        formData={showVendorModal?.formData}
        handleClose={handleVendorSubmit}
      />
    </div>
  );
}
const mapStateToProps = (state) => ({
  data: state.products.list,
  editData: state.products.product,
  etsySyncIds: state.sync.etsyIds,
  etsyGoldSyncIds: state.sync.etsyGoldIds,
  shopifySyncIds: state.sync.shopifyIds,
  pagination: state.products.pagination,
  filter: state.products.filter,
  PRODUCT_REFRESH: state.levent.PRODUCT_REFRESH,
  CMS_REFRESH: state.levent.CMS_REFRESH,
  cmsData: state.cms?.cms,
  defaultAttributes: state.cms?.cms
    ?.filter((i) => i.entity_id === "dim.product.attributes")
    ?.map((i) => JSON.parse(i.data)),
  products: state.products,
  category: state.category.data,
  cascadeCategory: state.category.cascade,
  isEdit: state.products.isEdit,
  unitData: state.unit.data,
  typeData: state.type?.data,
  vendorData: state.vendor.data,
});

const mapDispatchToProps = (dispatch) => ({
  editProduct: (data) =>
    dispatch({
      type: "PRODUCT_EDIT",
      data: data,
    }),
  syncOnEtsy: (productId) => dispatch(syncEtsy(productId)),
  syncOnEtsyGold: (productId) => dispatch(syncEtsyGold(productId)),
  syncOnShopify: (productId) => dispatch(syncShopify(productId)),
  syncOnShopifyTJ: (productId) => dispatch(syncShopifyTJ(productId)),
  getData: (data) => dispatch(fetchDataById(data)),
  getCms: (data) => dispatch(fetchCms(data)),
  addCms: (data, entity) => dispatch(addCMS(data, entity)),
  addUnit: (data, vendor) => dispatch(createOrUpdateUnit(data, vendor)),
  removeUnit: (data, vendor) => dispatch(deleteUnit(data, vendor)),
  removeCms: (data) => dispatch(deleteCMS(data)),
  updateVendor: (id, data) => dispatch(updateVendor(id, data)),
  createProduct: (data) => dispatch(createData(data)),
  updateProduct: (productId, data) => dispatch(putData(productId, data)),
  getVendors: () => dispatch(fetchVendor()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageAddProduct);
