
const emailSubscriber = (state = {list:[], isDetail : false}, action) => {
  switch (action.type) {

    case 'EMAIL_SUB_LIST':

      state.list = action.data;
      return state;

      case 'EMAIL_SUB_VIEW':

        state.item = action.data;
        state.isEdit = true;
        return state;
      case 'EMAIL_SUB_ADD':

        state.item = null;
        state.isEdit = false;
        return state;
    default:
      return state
  }
}
export default emailSubscriber