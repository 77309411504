const defaultCMS = {};

const cms = (
  state = {
    category: [],
    banner: [],
    coupon: [],
    notification: [],
    configuration: [],
    user: [],
    tags: [],
    cms: [],
    entity: [],
    isEdit: false,
  },
  action
) => {
  switch (action.type) {
    case "CATEGORY_LIST": {
      const category = action?.data?.map((item) => {
        return {
          ...item,
          value: item.category_id,
          key: item.category_id,
          label: item.name,
        };
      });

      return { ...state, category };
    }

    case "TAGS_LIST":
      return { ...state, tags: action.data };

    case "CMS_LIST":
      return { ...state, cms: action.data };
    case "ENTITY_LIST":
      return { ...state, entity: action.data };

    case "BANNER_LIST":
      return { ...state, banner: action.data };

    case "COUPON_LIST":
      return { ...state, coupon: action.data };
    case "NOTIFICATION_LIST":
      return { ...state, notification: action.data };
    case "CONFIGURATION_LIST":
      return { ...state, configuration: action.data };
    case "USER_LIST":
      return { ...state, user: action.data };

    case "ORDER_LIST":
      return { ...state, order: action.data };
    case "LOOKUP":
      const category = action?.data?.category?.map((item) => {
        return {
          ...item,
          value: item.category_id,
          key: item.category_id,
          label: item.name,
        };
      });

      const application = action?.data?.application?.map((item) => {
        return {
          ...item,
          value: item.application_id,
          key: item.application_id,
          label: item.name,
        };
      });

      const tags = action?.data?.tags?.map((item) => {
        return {
          ...item,
          value: item.title,
          key: item.tag_id,
          label: item.title,
        };
      });

      return {
        ...state,
        lookup: {
          category,
          application,
          tags,
        },
      };
    default:
      return state;
  }
};
export default cms;
