import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
// import { Line } from "react-chartjs-2";
import { Line, Pie } from "@ant-design/plots";

import { Statistic, Card, Col, Row } from "antd";
import { remove_storage } from "../utils/localstorage";

function PageDashboardAdmin({ data, getData, REFRESH, addData, updateData }) {
  useEffect(() => {
    // getData({ query: "" });
  }, [REFRESH]);

  useEffect(() => {
    remove_storage("productFilter");
  }, []);

  const daata = [
    {
      type: "Jewels & Chains Etsy",
      value: 27,
    },
    {
      type: "Jewels & Chains Shopify",
      value: 25,
    },
    {
      type: "Gold Etsy",
      value: 18,
    },
    {
      type: "Tresor Shopify",
      value: 15,
    },
  ];
  const config = {
    appendPadding: 10,
    data: daata,
    angleField: "value",
    colorField: "type",
    radius: 0.7,
    innerRadius: 0.4,
    label: {
      type: "inner",
      offset: "-50%",
      content: "{value}",
      style: {
        textAlign: "center",
        fontSize: 12,
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: "pre-wrap",
          overflow: "hidden",
          fontSize: 20,

          textOverflow: "ellipsis",
        },
      },
    },
  };

  const lineData = [
    {
      year: "Jan",
      value: 3000,
    },
    {
      year: "Feb",
      value: 2400,
    },
    {
      year: "Mar",
      value: 3500,
    },
    {
      year: "April",
      value: 3700,
    },
    {
      year: "May",
      value: 3200,
    },
  ];
  const lineConfig = {
    data: lineData,
    xField: "year",
    yField: "value",
    label: {},
    point: {
      size: 5,
      shape: "diamond",
      style: {
        fill: "white",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "red",
        },
      },
    },
    interactions: [
      {
        type: "marker-active",
      },
    ],
  };
  return (
    <div className="site-page-header-ghost-wrapper">
      <Row gutter={16}>
        <Col span={6}>
          <Card>
            <Statistic
              title="Total orders ( Today )"
              value={1200}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<DollarCircleOutlined />}
              // prefix="$"
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Total orders ( This Week )"
              value={24}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<ArrowDownOutlined />}
              suffix=""
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Total orders ( This Month )"
              value={24}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<ArrowDownOutlined />}
              suffix=""
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Total orders ( This Year )"
              value={24}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<ArrowDownOutlined />}
              suffix=""
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: 16 }}>
        <Col span={12}>
          <Card>
            <Statistic
              title="Total orders ( Today )"
              value={1200}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<DollarCircleOutlined />}
              // prefix="$"
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title="Total orders ( This Week )"
              value={24}
              precision={0}
              valueStyle={{ color: "#3f8600" }}
              // prefix={<ArrowDownOutlined />}
              suffix=""
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: 16 }}>
        <Col span={12} style={{ height: 256 }}>
          <div style={{ backgroundColor: "white", padding: 8, height: 256 }}>
            <h1 style={{ margin: 0 }}>Total products on Platforms</h1>
            <Pie {...config} />
          </div>
        </Col>
        <Col span={12} style={{ height: 256 }}>
          <div style={{ backgroundColor: "white", height: 256 }}>
            <div style={{ padding: 8, height: 220 }}>
              <h1 style={{ margin: 0 }}>Revenue in last 5 months</h1>
              <Line {...lineConfig} />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state?.organiser?.list,
  REFRESH: state?.levent?.DASHBOARD_REFRESH,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PageDashboardAdmin);
