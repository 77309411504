import { Select, Divider, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from "react";

const { Option } = Select;

let index = 0;

export default function SectionSelect({data = [],onItemAdd, onSelect,placeholder,selected = [], addItem, isItemAdd = true, isMultiple = true}){


  const [newItem, addNewItem] = useState("");
  const onNameChange = event => {
   addNewItem(event.target.value);
  };

  // const addItem = () => {
  //   // console.log('addItem');
  //   // const { items, name } = this.state;
  //   // this.setState({
  //   //   items: [...items, name || `New item ${index++}`],
  //   //   name: '',
  //   // });
  // };

    return (
      <Select
        placeholder={placeholder}
        mode={isMultiple ? 'multiple' : 'single'}
        allowClear
        onChange={onSelect}
        defaultValue={selected}
        dropdownRender={menu => (
          <div>
            {menu}
           {isItemAdd &&  <Divider style={{ margin: '4px 0' }} />}
           {isItemAdd &&  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
              <Input style={{ flex: 'auto' }} value={newItem} onChange={onNameChange} />
              <a
                style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                onClick={() => {
                  if(newItem != "")
                  addItem(newItem)
                }}
              >
                <PlusOutlined /> Add item
              </a>
            </div> }
          </div>
        )}
      >
        {data.map(item => (
          <Option key={item.group_id}>{item.name}</Option>
        ))}
      </Select>
    );
  
}